<script setup>
import dayjs from 'dayjs'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { useGridRef, useODataSource, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle, useLookup } from '@/composables/data-grid'
import { useArchive } from '@/composables/archive'

import { usePlanningStore } from '@/stores/planning.store'
import { usePortalStore } from '@/stores/portal.store'

import { planningApi } from '@/apis/planning.api'

import { useNotify } from '@/composables/notify'

import ConfirmDialog from '@/components/shared/ConfirmDialog.vue'
import ProjectListItem from '@/components/shared/ProjectListItem.vue'
import TagChipsTemplate from '@/components/shared/ChipsTemplate.vue'

const gridId = 'project-details-grid'

const i18n = useI18n()
const { notifySuccess } = useNotify()
const t = i18n.t
const { gridRef, gridIsEditing } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()
const { archiveDisplayExpr, archiveCellTemplate } = useArchive()

const planningStore = usePlanningStore()
const portalStore = usePortalStore()

const props = defineProps({
  data: Object
})
const editors = {}

const projectId = props.data?.id
const warningDialog = ref(false)
const warnings = ref([])
const warningItem = ref(null)
const services = planningStore.getServices()

const dataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.projectListingItemServicesUrl)
dataSource.filter(['project_id', '=', projectId])

const getFilteredListingItems = (e) => {
  return {
    store: planningStore.listingItems.filter(item => item.listing === props.data.listing && (e.isEditing ? !item.archived : true)),
    sort: 'name',
  }
}

const getFilteredServices = (e) => {
  const basePredicate = gridIsEditing() ? (i) => !i.archived : () => true

  const tagPredicate = e?.data?.tag_id ? (i) => i.tags.includes(e.data?.tag_id) : () => true

  return {
    store: planningStore.getServices(),
    filter: (i) => basePredicate(i) && tagPredicate(i),
    sort: 'name',
  }
}

const columns = [
  {
    dataField: 'listing_item_id',
    caption: i18n.t('common.listingItem'),
    lookup: useLookup({
      dataSource: getFilteredListingItems,
      displayExpr: (i) => archiveDisplayExpr(i, (item) => {
        const listing = planningStore.listings.find(listing => listing.id === item.listing)
        return `${listing.name} - ${item.name}`
      }),
    }),
    setCellValue: (newData, value, currentData) => {
      newData.listing_item_id = value
      newData.service_id = null
    },
    validationRules: [{ type: 'required' }],
  },
  {
    dataField: 'tag_id',
    dataType: 'number',
    caption: i18n.t('common.tag'),
    lookup: useLookup({
      dataSource: {
        store: portalStore.getLocalizedTags(),
        sort: 'name',
      },
      displayExpr: (item) => `${item.name}`,
    }),
    setCellValue: (newData, value, currentData) => {
      newData.tag_id = value
      newData.service_id = null
    },
    cellTemplate: 'tag-cell-template',
  },
  {
    dataField: 'service_id',
    caption: i18n.t('common.service'),
    lookup: useLookup({
      dataSource: getFilteredServices,
      displayExpr: (item) => {
        const label = planningStore.getServiceName(item)
        return item.archived ? `${label} [${t('common.archived')}]` : label
      }
    }),
    cellTemplate: (element, rowData) => archiveCellTemplate(
      element, rowData,
      services.find(i => i.id === rowData.data.service),
      'name'
    ),
    setCellValue: (newData, value, currentData) => {
      newData.service_id = value
      if (!currentData.start) {
        newData.start = dayjs(props.data.start).toISOString()
      }
      if (!currentData.end) {
        newData.end = dayjs(props.data.end).toISOString()
      }
    },
  },
  {
    dataField: 'start', caption: i18n.t('common.start'), dataType: 'datetime',
    validationRules: [{ type: 'custom', validationCallback: (e) => { return e.value || !e.data.id }, message: i18n.t('error.validation.required') }],
  },
  { dataField: 'end', caption: i18n.t('common.end'), dataType: 'datetime',
    validationRules: [{ type: 'custom', validationCallback: (e) => { return e.value || !e.data.id }, message: i18n.t('error.validation.required') }],
  },
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.listingItem')), {
  colCount: 1,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 3,
      items: [
        { dataField: 'listing_item_id' },
        { dataField: 'tag_id', editorOptions: { readOnly: true } },
        { dataField: 'service_id', editorOptions: { readOnly: true, showClearButton: true } },
        { dataField: 'start' },
        { dataField: 'end' },
      ]
    }
  ]
}, {
  allowAdding: !props.data?.archived,
  allowUpdating: !props.data?.archived,
  allowDeleting: !props.data?.archived,
})

const onInitNewRow = (e) => {
  e.data.project_id = projectId
}

const onEditorPreparing = (e) => {
  if (e.parentType === 'dataRow' && e.dataField === 'listing_item_id') {
    if (e.row.data?.id && e.row.data?.listing_item_id) {
      e.editorOptions.readOnly = true
    }
  }
  if (e.parentType === 'dataRow' && e.dataField === 'service_id') {
    e.editorOptions.readOnly = !e.row.data?.tag_id
  }
  if (e.parentType === 'dataRow' && e.dataField === 'start') {
    if (!e.row.data?.id) {
      e.editorOptions.readOnly = true
    } else {
      e.editorOptions.onInitialized = (args) => {
        editors[e.dataField] = args.component
        if (!e.row.data?.start) {
          args.component.option('value', props.data.start)
        }
        args.component.option('min', dayjs(props.data.start).toISOString())
        args.component.option('max', dayjs(props.data.end).toISOString())
      }
    }
  }
  if (e.parentType === 'dataRow' && e.dataField === 'end') {
    if (!e.row.data?.id) {
      e.editorOptions.readOnly = true
    } else {
      e.editorOptions.onInitialized = (args) => {
        editors[e.dataField] = args.component
        if (!e.row.data?.end) {
          args.component.option('value', dayjs(props.data.end).toISOString())
        }
        args.component.option('min', dayjs(props.data.start).toISOString())
        args.component.option('max', dayjs(props.data.end).toISOString())
      }
    }
  }
}

const assignServiceAsync = async(assignItem, force) => {
  warnings.value = []
  try {
    await planningStore.upsertProjectListingItemServiceAsync({
      id: assignItem.id,
      service: assignItem.service_id,
      start: assignItem.start,
      end: assignItem.end,
      force: force,
    })
    warningItem.value = null
  } catch (error) {
    if (error.response.status === 409) {
      await planningStore.fetchProjectsAsync({all: true}, true)
      warnings.value = error.response.data?.details[0]?.errors[0]?.params?.map(i => {
        const projectId = +(i.event.project_id)
        const project = projectId ? planningStore.getProjectById(projectId) : planningStore.getVacationProject(t)
        return {
          start: dayjs(i.event.start).toISOString(),
          end: dayjs(i.event.end).toISOString(),
          project: project
        }
      })
      warningItem.value = assignItem
    } else throw error
  }
  if (warnings.value.length !== 0 && !force) {
    warningDialog.value = true
    return
  }
  notifySuccess(t('info.assignedSuccessfully'))
  gridRef.value.instance.cancelEditData()
  await gridRef.value.instance.refresh()
}

const onSaving = async(e) => {
  if (e.changes[0]?.type === 'insert') {
    e.cancel = true
    const payload = {
      project: e.changes[0].data.project_id,
      listing_item: e.changes[0].data.listing_item_id,
    }
    await planningStore.upsertProjectListingItemAsync(payload)
    gridRef.value.instance.cancelEditData()
    await gridRef.value.instance.refresh()
  } else if (e.changes[0]?.type === 'update') {
    e.cancel = true
    await assignServiceAsync({
      id: e.changes[0].key,
      service_id: e.changes[0].data.service_id,
      start: e.changes[0].data.start,
      end: e.changes[0].data.end,
    })
  }
}

const onRowRemoving = async(e) => {
  e.cancel = true
  await planningStore.deleteProjectListingItemAsync(e.data.project_listing_item)
  gridRef.value.instance.refresh()
}

const gridOptions = useDefaultGridOptions({
  id: gridId,
  dataSource: dataSource,
  columns: columns,
  editing: editing,
  onInitNewRow: onInitNewRow,
  onEditorPreparing: onEditorPreparing,
  onRowUpdating: (e) => { e.newData.id = e.key },
  onRowRemoving: onRowRemoving,
  onSaving: onSaving,
})

const getServiceChips = (tags) => {
  return tags.map(tag => ({
    id: tag,
    label: portalStore.getLocalizedTags().find(t => t.id === tag)?.name,
  }))
}

</script>

<template>
  <div>
    <confirm-dialog
      v-model="warningDialog"
      icon="mdi-alert"
      icon-color="warning"
      :message="`${t('warning.availabilityNotGuaranteed')}. ${t('info.wantToContinue')}`"
      @confirm="async() => await assignServiceAsync(warningItem, true)"
    >
      <q-list>
        <project-list-item
          v-for="warning in warnings"
          :key="warning"
          hide-journalist
          hide-location
          show-staff
          :start="warning.start"
          :end="warning.end"
          :item="warning.project"
        />
      </q-list>
    </confirm-dialog>
    <dx-data-grid
      ref="gridRef"
      v-bind="gridOptions"
    >
      <template #tag-cell-template="{ data: templateOptions }">
        <tag-chips-template :tags="getServiceChips([templateOptions.data.tag_id])" />
      </template>
    </dx-data-grid>
  </div>
</template>
