<script setup>

import { ref } from 'vue'

const props = defineProps({
  equipment: Object
})

const equipment = ref(props.equipment)

</script>

<template>
  <q-card class="my-card q-mb-md">
    <q-card-section
      horizontal
    >
      <q-img
        class="q-ma-md"
        :src="equipment.avatar_url"
        style="max-width: 200px; max-height: 200px;"
      />
      <q-separator
        vertical
        class="q-ma-md"
      />
      <q-card-section>
        <q-list>
          <q-item>
            <q-item-section>
              <q-item-label>{{ $t('common.name') }}</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ equipment.name }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section>
              <q-item-label>Feature</q-item-label>
            </q-item-section>
            <q-item-section>
              <q-item-label>Add more details here...</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card-section>
  </q-card>
</template>
