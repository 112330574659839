<script setup>
import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { useGridRef, useODataSource, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle } from '@/composables/data-grid'
import { useArchivedAtColumn, useArchivedColumn, useCompanyColumn } from '@/composables/data-grid-column'
import { useArchive } from '@/composables/archive'

import { planningApi } from '@/apis/planning.api'
import { usePlanningStore } from '@/stores/planning.store'

import ListingItemsGrid from './ListingItemsGrid.vue'
import ArchiveGridButton from '@/components/shared/ArchiveGridButton.vue'
import ArchivedGridToggle from '@/components/shared/ArchivedGridToggle.vue'
import ArchiveWarningDialog from '@/components/shared/ArchiveWarningDialog.vue'

const gridId = 'listings-grid'

const i18n = useI18n()
const planningStore = usePlanningStore()
const companies = (await planningStore.fetchCompaniesAsync({all: true}, true))?.filter(i => !i.is_rental)

const { gridRef } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()

const gridDataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.listingsUrl, {
  customParamsFn: () => { return showArchived.value ? { all: true } : {} }
})

const { archiveWarning, archiveButton, showArchived, archiveAsync, onRowPrepared, onRowRemoving } = useArchive({
  onArchive: async(id) => { await planningStore.archiveListingAsync(id) },
  onDelete: async(id, config) => { await planningStore.deleteListingAsync(id, config) },
  gridRef: gridRef,
})

const columns = [
  {
    type: 'buttons',
    buttons: [
      { name: 'edit', visible: (e) => !e.row?.data?.archived },
      { name: 'delete', visible: (e) => !e.row?.data?.archived },
      archiveButton,
    ],
    fixed: false,
    visibleIndex: -1,
    width: '6rem',
  },
  {
    dataField: 'name',
    caption: i18n.t('common.name'),
    validationRules: [{ type: 'required' }],
    sortOrder: 'asc',
  },
  {
    dataField: 'description',
    caption: i18n.t('common.description'),
  },
  useCompanyColumn({ companies }),
  useArchivedAtColumn(),
  useArchivedColumn(),
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.listing')), {
  colCount: 1,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 4,
      items: [
        { dataField: 'company' },
        { dataField: 'name' },
        { dataField: 'description', colSpan: 2 },
      ]
    },
  ],
})

const onRowInserting = async(e) => (e.data.type = planningStore.serviceTypeEnum.STAFF)

const gridOptions = useDefaultGridOptions({
  id: gridId,
  columns: columns,
  dataSource: gridDataSource,
  editing: editing,
  selection: { mode: 'multiple', showCheckBoxesMode: 'always' },
  masterDetail: {
    enabled: true,
    template: 'master-detail',
  },
  onRowInserting: onRowInserting,
  onRowPrepared: onRowPrepared,
  onRowRemoving: onRowRemoving,
  clearFilterButton: true,
  exportExcelButton: true,
  archivedToggle: true,
  onExportExcel: async(e) => { await planningStore.exportListingsAsync(e.component.getSelectedRowKeys()) },
})

</script>
<template>
  <div>
    <archive-warning-dialog
      v-model="archiveWarning"
      @confirm="async() => await archiveAsync(archiveWarning.item.id, false)"
    />
    <dx-data-grid
      ref="gridRef"
      v-bind="gridOptions"
    >
      <template #master-detail="{ data: listing }">
        <listing-items-grid
          :data="listing"
          :show-archived="showArchived"
          model
        />
      </template>
      <template #archive-button="{ data: row }">
        <archive-grid-button :row="row" />
      </template>
      <template #archived-toggle>
        <archived-grid-toggle
          :show-archived="showArchived"
          :grid-ref="gridRef"
          @update:show-archived="showArchived = $event"
        />
      </template>
    </dx-data-grid>
  </div>
</template>
