<script setup>
import { useI18n } from 'vue-i18n'
import { useVModel } from '@vueuse/core'

import ConfirmDialog from '@/components/shared/ConfirmDialog.vue'
import ProjectListItem from '@/components/shared/ProjectListItem.vue'

const i18n = useI18n()

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
    default: () => ({
      dialogShow: false,
      warnings: [],
    }),
  },
})

const emit = defineEmits(['update:modelValue', 'confirm', 'cancel'])
const value = useVModel(props, 'modelValue', emit)

</script>
<template>
  <confirm-dialog
    v-model="value.dialogShow"
    icon="mdi-alert"
    icon-color="warning"
    :message="`${value.message}. ${i18n.t('warning.archiveSuggestion')}?`"
    @cancel="emit('cancel')"
    @confirm="emit('confirm')"
  >
    <q-list v-if="value.warnings?.length">
      <project-list-item
        v-for="warning in value.warnings"
        :key="warning"
        hide-journalist
        hide-location
        show-staff
        :start="warning.project.start"
        :end="warning.project.end"
        :item="warning.project"
      />
    </q-list>
  </confirm-dialog>
</template>
