<script setup>
import { usePortalStore } from '@/stores/portal.store'

const portalStore = usePortalStore()

const onIncreaseFontClick = () => portalStore.increaseFontSize()
const onDecreaseFontClick = () => portalStore.decreaseFontSize()

</script>
<template>
  <div>
    <q-btn
      v-ripple
      rounded
      color="primary"
      label="A+"
      class="large-btn q-mr-sm"
      :disabled="portalStore.fontSize >= 1.1"
      @click="onIncreaseFontClick"
    />
    <q-btn
      v-ripple
      rounded
      color="primary"
      label="A-"
      class="large-btn"
      :disabled="portalStore.fontSize <= 0.9"
      @click="onDecreaseFontClick"
    />
  </div>
</template>
