<script setup>

import dayjs from 'dayjs'

import { ref, computed } from 'vue'

import i18n from '@/plugins/i18n'
import { useNotify } from '@/composables/notify'
import { useStepper } from '@/composables/stepper'
import { useNavigator } from '@/composables/navigator'

import { usePlanningStore } from '@/stores/planning.store'
import { usePortalStore } from '@/stores/portal.store'
import { useScheduleStore } from '@/stores/schedule.store'

import EquipmentRental from '@/components/planner/EquipmentRental.vue'
import NavigateBack from '@/components/shared/NavigateBack.vue'
import StepperNavigation from '@/components/shared/StepperNavigation.vue'
import ListItem from '@/components/shared/ListItem.vue'
import DateSelector from '@/components/shared/DateSelector.vue'
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue'
import ProjectListItem from '@/components/shared/ProjectListItem.vue'
import TimeSelector from '@/components/shared/TimeSelector.vue'

const t = i18n.global.t
const planningStore = usePlanningStore()
const portalStore = usePortalStore()
const scheduleStore = useScheduleStore()
const { notifySuccess } = useNotify()

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  project: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['update', 'cancel'])
const editItem = ref(props.modelValue)
const { step, nextStep, prevStep } = useStepper({ step: editItem.value.service_id ? 2 : 1})

const dataSource = ref([])
const serviceAvailability = ref([])
const serviceEvents = ref([])
const warningDialog = ref(false)
const warnings = ref([])

useNavigator(() => {
  if (!rentedItem.value) {
    emit('cancel')
  }
})

await planningStore.fetchProjectsAsync({all: true}, true)

const updateDataSourceAsync = async() => {
  dataSource.value = (await planningStore.fetchServicesAsync({ tag: props.modelValue.tag_id, start: props.project.start, end: props.project.end }))?.data
}

const updateServiceAvailabilityEventsAsync = async(serviceId) => {
  serviceEvents.value = (await scheduleStore.fetchEventsByServiceAsync(serviceId, {
    start: props.project.start,
    end: props.project.end,
  })).data

  serviceAvailability.value = serviceEvents.value.filter(i => i.type === scheduleStore.eventTypeEnum.SERVICE_AVAILABILITY)
}

await updateDataSourceAsync()
const selectedItem = ref(editItem.value.service_id ? dataSource.value.find(i => i.id === editItem.value.service_id) : null)
if (selectedItem.value) {
  await updateServiceAvailabilityEventsAsync(selectedItem.value.id)
}

const rentedItem = ref(null)
const onRentedItemClick = () => {
  rentedItem.value = {}
}

const getItemLabel = (item) => {
  return item.type === portalStore.serviceTypeEnum.EQUIPMENT ? item.name : `${item.name} ${item.first_name}`
}

const getItemCaption = (item) => {
  return item.tags.map(id => portalStore.getLocalizedTags().find(tag => tag.id === id)).map(tag => tag.name).join(', ')
}

// trim spaces and replace + with empty string
const getCleanPhone = (phone) => phone.replace(/\s/g, '')

const onWhatsAppClick = () => {
  // window.open(`https://api.whatsapp.com/send?phone=${cleanPhone}`)
  window.open(`https://wa.me/${getCleanPhone(selectedItem.value.phone)}`)
}

const onCallClick = () => {
  window.open(`tel:${getCleanPhone(selectedItem.value.phone)}`)
}

const getAvailabilityColor = (availability) => {
  switch (availability) {
    case planningStore.serviceAvailabilityEnum.AVAILABLE:
      return 'positive'
    case planningStore.serviceAvailabilityEnum.PARTIALLY_AVAILABLE:
    case planningStore.serviceAvailabilityEnum.ASK:
      return 'warning'
  }
}

const isAvailableDate = (dateValue) => {
  if (selectedItem.value.availability_type === planningStore.serviceAvailabilityTypeEnum.ASK) {
    return true
  }

  const date = dayjs(dateValue)
  if (date.isBefore(dayjs(props.project.start), 'day') || date.isAfter(dayjs(props.project.end, 'day'))) {
    return false
  }

  if (selectedItem.value.availability_type === planningStore.serviceAvailabilityTypeEnum.USUALLY_UNAVAILABLE) {
    return serviceAvailability.value.some(i => i.project !== props.project.id && dayjs(i.start).isSameOrBefore(date) && dayjs(i.end).isSameOrAfter(date))
  }

  if (selectedItem.value.availability_type === planningStore.serviceAvailabilityTypeEnum.USUALLY_AVAILABLE) {
    return !serviceAvailability.value.some(i => i.project !== props.project.id && dayjs(i.start).isSameOrBefore(date) && dayjs(i.end).isSameOrAfter(date))
  }

  return false
}

const isEventDate = (dateValue) => {
  const date = dayjs(dateValue)
  return serviceEvents.value.filter(i => i.type === scheduleStore.eventTypeEnum.PROJECT).some(i => i.project !== props.project.id && date.isBetween(dayjs(i.start), dayjs(i.end), 'day', '[]'))
}

const eventDateColor = (dateValue) => {
  const date = dayjs(dateValue)
  const projectEvent = serviceEvents.value.filter(i => i.type === scheduleStore.eventTypeEnum.PROJECT).find(i => i.project !== props.project.id && date.isBetween(dayjs(i.start), dayjs(i.end), 'day', '[]'))
  return projectEvent ? 'warning' : 'primary'
}

const onAssignItem = async(item) => {
  selectedItem.value = item
  await updateServiceAvailabilityEventsAsync(selectedItem.value.id)
  if (isAvailableDate(props.project.start) && isAvailableDate(props.project.end)) {
    editItem.value.start = props.project.start
    editItem.value.end = props.project.end
  } else {
    editItem.value.start = null
    editItem.value.end = null
  }
  nextStep()
}

const onRentedItem = (item) => { rentedItem.value = item }

const onRentedItemUpdate = async(item) => {
  rentedItem.value = null
  await updateDataSourceAsync()
}

const assignServiceAsync = async(force = false) => {
  // Assign service to project
  warnings.value = []

  try {
    await planningStore.upsertProjectListingItemServiceAsync({
      id: props.modelValue.id,
      service: selectedItem.value.id,
      start: editItem.value.start,
      end: editItem.value.end,
      force: force,
    })
  } catch (error) {
    if (error.response.status === 409) {
      warnings.value = error.response.data['@odata.error'].details[0]?.errors[0]?.params?.map(i => {
        const projectId = +(i.event.project_id)
        const project = projectId ? planningStore.getProjectById(projectId) : planningStore.getVacationProject(t)
        return {
          start: dayjs(i.event.start).toISOString(),
          end: dayjs(i.event.end).toISOString(),
          project: project
        }
      })
    } else throw error
  }

  if (warnings.value.length !== 0 && !force) {
    warningDialog.value = true
    return
  }

  notifySuccess(t('info.assignedSuccessfully'))
  emit('update')
}

const onFinish = async() => await assignServiceAsync()

const isWarning = computed(() => {
  if (!editItem.value.start || !editItem.value.end) {
    return false
  }

  const startDate = dayjs(editItem.value.start)
  const endDate = dayjs(editItem.value.end)

  return serviceEvents.value.filter(i => i.type === scheduleStore.eventTypeEnum.PROJECT).some(i => i.project !== props.project.id && startDate.isBetween(dayjs(i.start), dayjs(i.end), 'day', '[]')) ||
    serviceEvents.value.filter(i => i.type === scheduleStore.eventTypeEnum.PROJECT).some(i => i.project !== props.project.id && endDate.isBetween(dayjs(i.start), dayjs(i.end), 'day', '[]'))
})

const isValid = computed(() => {
  if (!editItem.value.start || !editItem.value.end) {
    return false
  }

  return true
})

const onDateUpdated = (value) => {
  const startDate = dayjs(props.project.start)
  const endDate = dayjs(props.project.end)

  editItem.value.start = dayjs(editItem.value.start).set('hour', startDate.hour()).set('minute', startDate.minute()).toISOString()
  editItem.value.end = dayjs(editItem.value.end).set('hour', endDate.hour()).set('minute', endDate.minute()).toISOString()
}

</script>
<template>
  <q-card>
    <q-card-section>
      <q-list>
        <navigate-back
          :text="editItem.name || editItem.tag.name"
          @back="emit('cancel')"
        />
      </q-list>
    </q-card-section>
    <q-card-section class="q-px-sm q-pt-none">
      <q-dialog
        v-if="editItem.tag.type === portalStore.serviceTypeEnum.EQUIPMENT"
        :model-value="rentedItem !== null"
        maximized
        persistent
      >
        <equipment-rental
          v-if="rentedItem !== null"
          v-model="rentedItem"
          :project="props.project"
          :tag-id="editItem.tag_id"
          @update="onRentedItemUpdate"
          @close="rentedItem = null"
          @cancel="rentedItem = null"
        />
      </q-dialog>
      <confirm-dialog
        v-model="warningDialog"
        icon="mdi-alert"
        icon-color="warning"
        :message="`${t('warning.availabilityNotGuaranteed')}. ${t('info.wantToContinue')}`"
        @confirm="async() => await assignServiceAsync(true)"
      >
        <q-list>
          <project-list-item
            v-for="warning in warnings"
            :key="warning"
            hide-journalist
            hide-location
            show-staff
            :start="warning.start"
            :end="warning.end"
            :item="warning.project"
          />
        </q-list>
      </confirm-dialog>

      <q-stepper
        v-model="step"
        class="q-py-none"
      >
        <q-step
          :name="1"
          :title="`${t('common.selectA')} ${editItem.tag.name}`"
          icon="mdi-folder-plus"
          :done="step > 1 || !!editItem.service_id"
          :header-nav="step > 1 || !!editItem.service_id"
        >
          <q-list
            padding
          >
            <list-item
              v-for="availableItem in dataSource"
              :key="availableItem.id"
              :avatar="availableItem?.avatar_file || availableItem?.avatar_url"
              :label="getItemLabel(availableItem)"
              :caption="getItemCaption(availableItem)"
              :caption-chips="availableItem.rental_company ? [t('common.rented')] : []"
              :icon="availableItem.availability === planningStore.serviceAvailabilityEnum.ASK ? 'mdi-calendar-question' : 'mdi-calendar-plus'"
              :icon-color="getAvailabilityColor(availableItem.availability)"
              clickable
              @click="availableItem.rental_company ? onRentedItem(availableItem) : onAssignItem(availableItem)"
              @icon-click="onAssignItem(availableItem)"
            />
          </q-list>
          <q-item
            v-if="editItem.tag.type === portalStore.serviceTypeEnum.EQUIPMENT"
            class="align-middle align-center q-pt-md"
          >
            <q-btn
              color="primary"
              :label="t('project.assign.addForRentEquipment')"
              @click="onRentedItemClick"
            />
          </q-item>
          <stepper-navigation
            :back="() => emit('cancel')"
          />
        </q-step>
        <q-step
          :name="2"
          :title="t('project.assign.checkAvailability')"
          icon="mdi-folder-plus"
          class="items-center"
          :done="step > 2 && selectedItem !== null"
          :header-nav="step > 2 && selectedItem !== null"
        >
          <q-item class="q-px-none">
            <q-item-section avatar>
              <q-avatar>
                <q-img
                  v-if="selectedItem?.avatar_file || selectedItem?.avatar_url"
                  :src="selectedItem.avatar_file || selectedItem.avatar_url"
                />
                <q-icon
                  v-else
                  name="mdi-camera-off"
                  size="md"
                  color="grey-5"
                />
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ getItemLabel(selectedItem) }}</q-item-label>
            </q-item-section>
            <q-item-section
              v-if="editItem.tag.type === portalStore.serviceTypeEnum.STAFF"
              side
            >
              <div>
                <q-btn
                  round
                  color="blue"
                  size="lg"
                  flat
                  icon="mdi-phone"
                  @click="onCallClick"
                />
                <q-btn
                  round
                  color="positive"
                  size="lg"
                  flat
                  icon="mdi-whatsapp"
                  @click="onWhatsAppClick"
                />
              </div>
            </q-item-section>
          </q-item>
          <q-item class="q-px-none">
            <date-selector
              v-model="editItem"
              :available-dates="isAvailableDate"
              :events="isEventDate"
              :event-color="eventDateColor"
              class="q-mt-sm"
              @update:model-value="onDateUpdated"
            />
          </q-item>
          <q-item class="q-pl-none q-mr-sm">
            <time-selector
              v-model="editItem.start"
              class="q-mt-sm"
              :label="t('common.startTime')"
            />
            <time-selector
              v-model="editItem.end"
              end
              class="q-mt-sm"
              :label="t('common.endTime')"
            />
          </q-item>
          <stepper-navigation
            :back="prevStep"
            :finish="onFinish"
            :color="isWarning ? 'warning' : 'positive'"
            :icon-right="isWarning ? 'mdi-alert' : undefined"
            :finish-label="t('project.assign.assignToProject')"
            :disable="!isValid"
          />
        </q-step>
      </q-stepper>
    </q-card-section>
  </q-card>
</template>
