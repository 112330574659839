<script setup>
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { useGridRef, useODataSource, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle } from '@/composables/data-grid'

import { useNotify } from '@/composables/notify'

import { scheduleApi } from '@/apis/schedule.api'

import { useScheduleStore } from '@/stores/schedule.store'

const gridId = 'service-availabilities-grid'

const i18n = useI18n()
const scheduleStore = useScheduleStore()

const props = defineProps({
  data: Object,
})
const editors = {}
const serviceId = props.data?.id

const { gridRef } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()
const { notifyWarning } = useNotify()

const gridDataSource = useODataSource(scheduleApi.getAbsoluteUrl() + scheduleApi.eventsUrl,
  {
    filter: ['service_id', '=', serviceId]
  }
)

const columns = [
  { dataField: 'start', caption: i18n.t('common.start'), dataType: 'date', validationRules: [{ type: 'required' }], sortOrder: 'asc',
    setCellValue: (newData, value, currentData) => {
      newData.start = value.toISOString()
      newData.end = null
    } },
  { dataField: 'end', caption: i18n.t('common.end'), dataType: 'date', validationRules: [{ type: 'required' }] },
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.availability')), {
  colCount: 1,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 2,

      items: [
        { dataField: 'start' },
        { dataField: 'end' },
      ]
    },
  ],
}, {
  allowAdding: !props.data?.archived,
  allowUpdating: !props.data?.archived,
  allowDeleting: !props.data?.archived,
})

const onEditorPreparing = (e) => {
  if (e.parentType === 'dataRow' && e.dataField === 'start') {
    const defaultOnValueChanged = e.editorOptions.onValueChanged
    e.editorOptions.onInitialized = (args) => {
      editors[e.dataField] = args.component
    }
    e.editorOptions.onValueChanged = (args) => {
      editors['end'].option('min', args.value)
      defaultOnValueChanged(args)
    }
    e.promise = scheduleStore.fetchEventsByServiceAsync(serviceId, {
      type: scheduleStore.eventTypeEnum.SERVICE_AVAILABILITY,
    }).then((res) => {
      if ('start' in editors) {
        const dates = res.data
        const disabledDate = (args) => {
          const currentDate = dayjs(args.date)
          if (e.row?.data?.id) {
            if (currentDate.isSameOrAfter(e.row.data.start) && currentDate.isSameOrBefore(e.row.data.end)) {
              return false
            }
          }
          return dates.some(i => currentDate.isBetween(dayjs(i.start), dayjs(i.end), null, '[]'))
        }
        editors['start'].option('disabledDates', disabledDate)
        editors['end'].option('disabledDates', disabledDate)
      }
    })
  }
  if (e.parentType === 'dataRow' && e.dataField === 'end') {
    e.editorOptions.onInitialized = (args) => {
      editors[e.dataField] = args.component
      if (e.row.data?.start) {
        args.component.option('min', e.row.data.start)
      }
    }
  }
}

const onSaving = async(e) => {
  if (e.changes[0]?.type === 'insert' || e.changes[0]?.type === 'update') {
    e.cancel = true
    const payload = {
      'start': e.changes[0].data.start,
      'end': e.changes[0].data.end,
      'service': serviceId,
      'type': scheduleStore.eventTypeEnum.SERVICE_AVAILABILITY,
    }
    if (e.changes[0]?.type === 'update') {
      payload.id = e.changes[0].key
    }
    try {
      await scheduleApi.upsertEventAsync(payload)
      gridRef.value.instance.cancelEditData()
      gridRef.value.instance.refresh()
    } catch (error) {
      if (error.response.status === 409) {
        const warnings = error.response.data?.details[0]?.errors[0]?.params

        let warningMessage = i18n.t('warning.eventAvailabilityOverlap')

        warningMessage += warnings ? `<br/><ul> ${warnings.map(i => `<li>${dayjs(i.start).format('YYYY/MM/DD')} - ${dayjs(i.end).format('YYYY/MM/DD')}</li>`).join('')} </ul>` : ''

        notifyWarning(i18n.t('error.validation.overlapping'), {
          message: warningMessage,
          html: true
        })
      } else throw error
    }
  }
}

const gridOptions = useDefaultGridOptions({
  id: gridId,
  columns: columns,
  dataSource: gridDataSource,
  editing: editing,
  onEditorPreparing: onEditorPreparing,
  onSaving: onSaving,
  clearFilterButton: true,
})

</script>
<template>
  <div>
    <dx-data-grid
      ref="gridRef"
      v-bind="gridOptions"
    />
  </div>
</template>
