<script setup>
import { ref, onMounted } from 'vue'

import { useWebSiteStore } from '@/stores/web-site.store'
import { usePlanningStore } from '@/stores/planning.store'

import ProjectListItem from '@/components/shared/ProjectListItem.vue'
import ProjectDetail from './ProjectDetail.vue'
import ProjectEdit from './ProjectEdit.vue'

import { useDetectDevice } from '@/composables/device'

const { isMobile } = useDetectDevice()

const webSiteStore = useWebSiteStore()
const planningStore = usePlanningStore()

webSiteStore.fabActions = [
  {
    id: 'add',
    icon: 'mdi-folder-plus',
    onClick: () => { editItem.value = {} },
  },
]

const editItem = ref(null)
const detailItem = ref(null)

const dataSource = ref([])

const onProjectClick = (item) => { detailItem.value = item }

const onChange = async() => {
  editItem.value = null
  detailItem.value = null
  await updateDataSourceAsync()
}

const updateDataSourceAsync = async() => {
  await planningStore.fetchProjectsAsync({all: true}, true)
  dataSource.value = planningStore.projects
}

onMounted(async() => {
  await updateDataSourceAsync()
  if (webSiteStore.editedProjectId) {
    detailItem.value = dataSource.value.find(i => i.id === webSiteStore.editedProjectId)
    webSiteStore.editedProjectId = null
  }
})

</script>
<template>
  <q-card
    flat
  >
    <q-card-section>
      <q-list>
        <project-list-item
          v-for="project in dataSource"
          :key="project.id"
          v-ripple
          clickable
          :item="project"
          @click="onProjectClick"
        />
      </q-list>
      <q-dialog
        :model-value="editItem !== null"
        :maximized="isMobile"
        persistent
      >
        <project-edit
          v-if="editItem !== null"
          :model-value="editItem"
          @update="onChange"
          @cancel="onChange"
          @delete="onChange"
        />
      </q-dialog>
      <q-dialog
        :model-value="detailItem !== null"
        :maximized="isMobile"
        persistent
      >
        <project-detail
          v-if="detailItem !== null"
          :model-value="detailItem"
          @update="onChange"
          @cancel="onChange"
        />
      </q-dialog>
    </q-card-section>
  </q-card>
</template>
