<script setup>

import { stringToRgb } from '@/lib/utils'

const props = defineProps({
  tags: {
    type: Array,
    required: true,
  },
})

</script>

<template>
  <q-chip
    v-for="tag in props.tags"
    :key="tag.id"
    size="sm"
    :label="tag.label"
    :style="{ 'background-color': `rgb(${stringToRgb(tag.label).join(',')})` }"
  />
</template>
