<script setup>
import dayjs from 'dayjs'
import i18n from '@/plugins/i18n'

import { ref, computed } from 'vue'

import { usePlanningStore } from '@/stores/planning.store'
import { usePortalStore } from '@/stores/portal.store'

import ListItem from '@/components/shared/ListItem.vue'
import NavigateBack from '@/components/shared/NavigateBack.vue'

import { useNotify } from '@/composables/notify'
import { useDetectDevice } from '@/composables/device'
import { useNavigator } from '@/composables/navigator'

import { stringToRgb } from '@/lib/utils'

import ProjectAssign from './ProjectAssign.vue'
import ProjectEdit from './ProjectEdit.vue'

const { isMobile } = useDetectDevice()

const t = i18n.global.t
const { notifySuccess } = useNotify()
const planningStore = usePlanningStore()
const portalStore = usePortalStore()

useNavigator(() => {
  if (!editItem.value && !assignItem.value) {
    emit('cancel')
  }
})

await Promise.all([
  planningStore.fetchListingItemsAsync({all: true}, true),
  portalStore.fetchTagsAsync({all: true}, true)
])

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['cancel', 'update'])

const projectListingItemServices = ref([])
const projectItem = ref(props.modelValue)
const editItem = ref(null)
const assignItem = ref(null)

const refreshProject = async() => {
  projectItem.value = (await planningStore.fetchProjectByIdAsync(projectItem.value.id)).data
}

const refreshProjectItemServicesAsync = async() => {
  projectListingItemServices.value = (await planningStore.fetchProjectListingItemServicesByProjectIdAsync(props.modelValue.id)).data?.map(i => ({
    ...i,
    tag: portalStore.getLocalizedTags().find(t => t.id === i.tag_id),
  })) || []
}
await refreshProjectItemServicesAsync()

const staffTagServices = computed(() => projectListingItemServices.value.filter(i => i.tag.type === portalStore.serviceTypeEnum.STAFF).sortBy(i => i.tag.name))
const equipmentTagServices = computed(() => projectListingItemServices.value.filter(i => i.tag.type === portalStore.serviceTypeEnum.EQUIPMENT).sortBy(i => i.tag.name))

const onAssignItem = async(item) => {
  if (!item.service_id) {
    item.start = projectItem.value.start
    item.end = projectItem.value.end
  }
  assignItem.value = item
}

const onUnassignItem = async(item) => {
  await planningStore.upsertProjectListingItemServiceAsync({ id: item.id, service: null })
  notifySuccess('Assignment removed')
  await refreshProjectItemServicesAsync()
}

const onAssignUpdate = async() => {
  assignItem.value = null
  await refreshProjectItemServicesAsync()
}

const selectionKey = ref(0)

const onProjectUpdate = async() => {
  editItem.value = null
  await Promise.all([
    refreshProject(),
    refreshProjectItemServicesAsync(),
  ])
}

const onProjectDelete = async() => {
  editItem.value = null
  onCancel()
}

const getServiceChips = (item) => {
  if (item.listing_item_id) {
    const name = planningStore.listingItems.find(i => i.id === item.listing_item_id)?.name
    return [{label: name, color: `rgb(${stringToRgb(name).join(',')})`}]
  }
  return null
}

const onCancel = () => emit('cancel')

</script>
<template>
  <q-card>
    <q-card-section>
      <q-list>
        <navigate-back
          :text="projectItem.name"
          :icon="projectItem.status !== planningStore.projectStatusEnum.PLANNED ? 'mdi-magnify' : 'mdi-pencil'"
          @back="onCancel"
          @click="editItem = projectItem"
        />
      </q-list>
    </q-card-section>
    <q-card-section class="q-pt-none">
      <q-list>
        <q-item>
          <div style="width: 50%; float: left;">
            <q-item-section>
              <q-item-label caption>
                {{ $t('common.period') }}
              </q-item-label>
              <q-item-label class="align-items-baseline">
                <q-icon
                  name="mdi-clock-outline"
                  class="q-mr-xs"
                />
                <span>
                  {{ dayjs(projectItem.start).format('HH:mm') }} - {{ dayjs(projectItem.end).format('HH:mm') }}
                </span>
              </q-item-label>
              <q-item-label class="align-items-baseline">
                <q-icon
                  name="mdi-calendar"
                  class="q-mr-xs"
                />
                <span>
                  {{ dayjs(projectItem.start).format('DD/MM') }} to {{ dayjs(projectItem.end).format('DD/MM') }}
                </span>
              </q-item-label>
            </q-item-section>
          </div>
          <div style="width: 50%; float: right;">
            <q-item-section
              style="display: flex; align-content: flex-end;"
            >
              <q-item-label
                caption
                style="text-align: right;"
              >
                {{ $t('common.status') }}
              </q-item-label>
              <q-item-label>
                <q-chip
                  size="sm"
                  style="margin: 0"
                  :label="planningStore.getLocalizedProjectStatuses()[projectItem.status].name"
                  :color="planningStore.getProjectStatusColor(projectItem.status)"
                />
              </q-item-label>
            </q-item-section>
          </div>
        </q-item>
        <q-item>
          <div style="width: 50%; float: left;">
            <q-item-section>
              <q-item-label caption>
                {{ $t('common.location') }}
              </q-item-label>
              <q-item-label>{{ projectItem.location }}</q-item-label>
            </q-item-section>
          </div>
          <div style="width: 50%; float: right;">
            <q-item-section
              style="display: flex; align-content: flex-end;"
            >
              <q-item-label
                caption
                style="text-align: right;"
              >
                {{ $t('common.journalist') }}
              </q-item-label>
              <q-item-label>{{ projectItem.journalist }}</q-item-label>
            </q-item-section>
          </div>
        </q-item>
        <q-item v-if="projectItem.description">
          <q-item-section>
            <q-item-label caption>
              {{ $t('common.description') }}
            </q-item-label>
            <q-item-label>{{ projectItem.description }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-card-section>
    <q-separator />
    <q-card-section class="q-pt-none">
      <q-list
        :key="selectionKey"
        padding
      >
        <q-item-label
          header
        >
          {{ t('common.staff', staffTagServices?.length) }}
        </q-item-label>
        <list-item
          v-for="staff in staffTagServices"
          :key="staff.id"
          :avatar="staff.service_id ? (staff.service_avatar_file || staff.service_avatar_url) : null"
          :avatar-icon="staff.service_id ? null : 'mdi-crop-free'"
          :label="staff.service_id ? `${staff.service_name} ${staff.service_first_name}` : staff.tag.name"
          :caption="staff.service_id ? staff.tag.name : null"
          clickable
          :caption-chips="getServiceChips(staff)"
          :icon="staff.service_id ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'"
          :icon-color="staff.service_id ? 'negative' : null"
          @click="onAssignItem(staff)"
          @icon-click="!staff.service_id ? onAssignItem(staff) : onUnassignItem(staff)"
        />
      </q-list>
      <q-list
        :key="selectionKey"
        class="q-mt-sm"
      >
        <q-item-label
          header
        >
          {{ t('common.equipment', equipmentTagServices?.length) }}
        </q-item-label>
        <list-item
          v-for="equipment in equipmentTagServices"
          :key="equipment.id"
          :avatar="equipment.service_id ? (equipment.service_avatar_file || equipment.service_avatar_url) : null"
          :avatar-icon="equipment.service_id ? null : 'mdi-crop-free'"
          :label="equipment.service_id ? equipment.service_name : equipment.tag.name"
          :caption="equipment.service_id ? equipment.tag.name : null"
          clickable
          :caption-chips="getServiceChips(equipment)"
          :icon="equipment.service_id ? 'mdi-minus-circle-outline' : 'mdi-plus-circle-outline'"
          :icon-color="equipment.service_id ? 'negative' : null"
          @click="onAssignItem(equipment)"
          @icon-click="!equipment.service_id ? onAssignItem(equipment) : onUnassignItem(equipment)"
        />
      </q-list>
    </q-card-section>
    <q-space />
  </q-card>
  <q-dialog
    :model-value="assignItem !== null"
    :maximized="isMobile"
    persistent
  >
    <project-assign
      v-if="assignItem !== null"
      :model-value="assignItem"
      :project="projectItem"
      @update="onAssignUpdate"
      @cancel="assignItem = null"
    />
  </q-dialog>
  <q-dialog
    :model-value="editItem !== null"
    :maximized="isMobile"
    persistent
  >
    <project-edit
      v-if="editItem !== null"
      :model-value="editItem"
      @delete="onProjectDelete"
      @update="onProjectUpdate"
      @cancel="editItem = null"
    />
  </q-dialog>
</template>
