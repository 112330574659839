<script setup>
import { useI18n } from 'vue-i18n'
import { DxTagBox } from 'devextreme-vue/tag-box'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { useGridRef, useODataSource, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle, useLookup, useCurrencyOptions } from '@/composables/data-grid'
import { useCurrencyColumn, usePriceColumn } from '@/composables/data-grid-column'
import { useArchivedAtColumn, useArchivedColumn } from '@/composables/data-grid-column'
import { useArchive } from '@/composables/archive'

import { usePortalStore } from '@/stores/portal.store'
import { useWebSiteStore } from '@/stores/web-site.store'

import { planningApi } from '@/apis/planning.api'
import { usePlanningStore } from '@/stores/planning.store'

import ArchiveGridButton from '@/components/shared/ArchiveGridButton.vue'
import ArchiveWarningDialog from '@/components/shared/ArchiveWarningDialog.vue'
import TagChipsTemplate from '@/components/shared/ChipsTemplate.vue'

import { stringToRgb } from '@/lib/utils'

const gridId = 'project-details-grid'

const props = defineProps({
  data: Object,
  showArchived: Boolean
})

const i18n = useI18n()
const { gridRef } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()
const { archiveWarning, archiveButton, archiveAsync, onRowPrepared, onRowRemoving } = useArchive({
  onArchive: async(id) => { await planningStore.archiveListingItemAsync(id) },
  onDelete: async(id, config) => { await planningStore.deleteListingItemAsync(id, config) },
  gridRef: gridRef,
})

const planningStore = usePlanningStore()
const portalStore = usePortalStore()
const webSiteStore = useWebSiteStore()
const editors = {}

const listingId = props.data?.key
const localizedTags = portalStore.getLocalizedTags()
const gridDataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.listingItemsUrl, {
  filter: ['listing_id', '=', listingId],
  customParamsFn: () => { return props.showArchived ? { all: true } : {} }
})

const columns = [
  {
    type: 'buttons',
    buttons: [
      { name: 'edit', visible: (e) => !e.row?.data?.archived },
      { name: 'delete', visible: (e) => !e.row?.data?.archived },
      archiveButton,
    ],
    fixed: false,
    visibleIndex: -1,
    width: '6rem',
  },
  {
    dataField: 'name',
    caption: i18n.t('common.name'),
    validationRules: [{ type: 'required' }],
    sortOrder: 'asc'
  },
  {
    dataField: 'description',
    caption: i18n.t('common.description'),
  },
  {
    dataField: 'tags',
    caption: i18n.t('common.tag', 2),
    lookup: useLookup({
      dataSource: localizedTags.sort((a, b) => a.name.localeCompare(b.name)),
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    setCellValue: (rowData, value) => (rowData.tags = value),
    cellTemplate: 'tag-cell-template',
    editCellTemplate: 'tag-edit-cell-template',
    validationRules: [{ type: 'required' }]
  },
  usePriceColumn(),
  useCurrencyColumn({editors: editors}),
  useArchivedAtColumn(),
  useArchivedColumn(),
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.listingItem')), {
  colCount: 1,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 5,
      items: [
        { dataField: 'name' },
        { dataField: 'description' },
        { dataField: 'tags', colSpan: 3 },
        { dataField: 'price', editorOptions: useCurrencyOptions() },
        { dataField: 'currency' }
      ]
    }
  ]
}, {
  allowAdding: !props.data?.data?.archived,
  allowUpdating: !props.data?.data?.archived,
  allowDeleting: !props.data?.data?.archived,
})

const onInitNewRow = (e) => {
  e.data.listing = listingId
  e.data.currency = webSiteStore.getDefaultCurrency()?.id
}

const onEditorPreparing = (e) => {
  if (e.parentType === 'dataRow' && e.dataField === 'price') {
    e.editorOptions.onInitialized = (e) => { editors['price'] = e.component }
    const currentCurrency = webSiteStore.currencies.find(i => i.id === e.row.data.currency)
    if (currentCurrency) {
      e.editorOptions.format = useCurrencyOptions({currency: currentCurrency.iso_code})?.format
    }
  }
}

const gridOptions = useDefaultGridOptions({
  id: gridId,
  dataSource: gridDataSource,
  columns: columns,
  editing: editing,
  onInitNewRow: onInitNewRow,
  onEditorPreparing: onEditorPreparing,
  onRowPrepared: onRowPrepared,
  onRowRemoving: onRowRemoving
})

const getServiceChips = (tags) => {
  return tags.map(tag => ({
    id: tag,
    label: localizedTags.find(t => t.id === tag).name,
  }))
}

</script>
<template>
  <div>
    <archive-warning-dialog
      v-model="archiveWarning"
      @confirm="async() => await archiveAsync(archiveWarning.item.id, false)"
    />
    <dx-data-grid
      ref="gridRef"
      v-bind="gridOptions"
    >
      <template #archive-button="{ data: row }">
        <archive-grid-button :row="row" />
      </template>
      <template #tag-cell-template="{ data: templateOptions }">
        <tag-chips-template :tags="getServiceChips(templateOptions.data.tags)" />
      </template>
      <template #tag-edit-cell-template="{ data: templateOptions }">
        <dx-tag-box
          :value="templateOptions.data.tags"
          :data-source="localizedTags"
          tag-template="tag-template"
          value-expr="id"
          display-expr="name"
          @value-changed="(e) => templateOptions.setValue(e.value)"
        >
          <template #tag-template="{ data: innerData }">
            <div
              class="dx-tag-content"
              :style="{ backgroundColor: `rgb(${stringToRgb(innerData.name).join(',')})` }"
            >
              <div class="dx-tag-remove-button" />
              <div class="dx-tag-text">
                {{ innerData.name }}
              </div>
            </div>
          </template>
        </dx-tag-box>
      </template>
    </dx-data-grid>
  </div>
</template>
