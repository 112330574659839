<script setup>

import { useI18n } from 'vue-i18n'
import { ref } from 'vue'

const i18n = useI18n()

const props = defineProps({
  row: {
    type: Object,
    required: true,
  },
})

const row = ref(props.row)
</script>

<template>
  <q-btn
    flat
    dense
    size="sm"
    color="primary"
    :title="row.data.archived_at ? i18n.t('common.unarchive') : i18n.t('common.archive')"
    :icon="row.data.archived_at ? 'mdi-archive-off' : 'mdi-archive'"
  />
</template>
