import { ref } from 'vue'
import { defineStore } from 'pinia'

import { setAuthorizationToken } from '@/apis/http.api'
import { webSiteApi } from '@/apis/web-site.api'
import router, { menuRoutes, menuRouteEnum } from '@/router'
import { useFetchAndStoreAsync } from '@/composables/store'

import { usePortalStore } from './portal.store'

export const initWebSiteStoreAsync = async() => {
  const store = useWebSiteStore()
  await Promise.all([
    store.fetchSettingsAsync({all: true}, true),
    store.fetchCurrenciesAsync({all: true}, true)
  ])
}

export const useWebSiteStore = defineStore('webSite', () => {
  const portalStore = usePortalStore()

  const user = ref(null)
  const users = ref([])
  const collaborator = ref({ id: 1 })
  const accessToken = ref(null)
  const refreshToken = ref(null)
  const refreshTokenTimeout = ref(null)
  const menuEntries = ref([])
  const fabActions = ref([])
  const showEventWarnings = ref(true)
  const showEventVacations = ref(true)
  const eventSchedulerDate = ref(null)
  const editedProjectId = ref(null)
  const currencies = ref([])
  const settings = ref({})

  const userTypes = {
    NONE: 0,
    ADMIN: 1,
    COLLABORATOR: 2,
    PLANNER: 4
  }

  const schedulerViews = {
    PROJECTS: 0,
    STAFF: 1,
    EQUIPMENT: 2,
  }

  const gridEditItemId = ref(null)
  const gridStates = ref({})
  const sideBarPinned = ref(false)
  const sideBarExpanded = ref(false)

  const isValidToken = (token, isRefresh = false) => {
    if (isRefresh) {
      return token && token.access
    }
    return token && token.refresh && token.access
  }

  const getMenuEntriesWithRoute = () => {
    return menuEntries.value.map(menuEntry => {
      const route = menuRoutes[menuEntry.code]
      if (route) {
        menuEntry.route = route
      }
      return menuEntry
    })
  }

  const loginAsync = async(username, password) => {
    if (!username || !password) {
      return logoutAsync()
    }
    const res = await webSiteApi.getTokenAsync(username, password)
    await setTokenAsync(res.data)
    await fetchMenuEntriesAsync({all: true}, true)
    portalStore.localizationsReset()
    return routeToHomeAsync()
  }

  const autoLoginAsync = async() => {
    try {
      await refreshTokenAsync()
      if (accessToken.value) {
        await fetchMenuEntriesAsync({all: true}, true)
      }
    } catch (err) {
      console.error(err)
      return logoutAsync()
    }
  }

  const routeToHomeAsync = async() => {
    if (refreshToken.value && accessToken.value) {
      return router.replace(menuRoutes[menuRouteEnum.HOME])
    } else {
      return router.push(menuRoutes[menuRouteEnum.LOGIN])
    }
  }

  const logoutAsync = async() => {
    $reset()
    return router.push(menuRoutes[menuRouteEnum.LOGIN])
  }

  const isAuthenticated = () => accessToken.value && refreshToken.value

  const refreshTokenAsync = async() => {
    if (!refreshToken.value) {
      return logoutAsync()
    }
    const res = await webSiteApi.getFreshTokenAsync(refreshToken.value)
    return setTokenAsync(res.data, true)
  }

  const setTokenAsync = async(token, isRefresh = false) => {
    if (!isValidToken(token, isRefresh)) {
      return logoutAsync()
    }
    accessToken.value = token.access
    if (!isRefresh) {
      refreshToken.value = token.refresh
    }
    setAuthorizationToken(accessToken.value)
    await startRefreshTokenTimerAsync()
  }

  const startRefreshTokenTimerAsync = async() => {
    // parse json object from base64 encoded jwt token
    const jwtBase64 = accessToken.value.split('.')[1]
    const jwtToken = JSON.parse(atob(jwtBase64))
    await setUserAsync(jwtToken.user_id)

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000)
    const timeout = expires.getTime() - Date.now() - (60 * 1000)
    refreshTokenTimeout.value = setTimeout(refreshTokenAsync, timeout)
  }

  const setUserAsync = async(userId) => {
    const res = await webSiteApi.getUserDetailsAsync(userId)
    if (!res.data) {
      return logoutAsync()
    }
    user.value = res.data
  }

  const setGridState = (gridName, gridState) => { gridStates.value[gridName] = gridState }

  const stopRefreshTokenTimer = () => clearTimeout(refreshTokenTimeout.value)

  const activateUserAsync = async(uid, token, password) => webSiteApi.activateUserAsync(uid, token, password)
  const fetchCurrenciesAsync = async(params, store = false) => useFetchAndStoreAsync(currencies, () => webSiteApi.getCurrenciesAsync(params), store)
  const fetchMenuEntriesAsync = async(params, store = false) => useFetchAndStoreAsync(menuEntries, () => webSiteApi.getMenuEntries(params), store)
  const fetchSettingsAsync = async(params, store = false) => useFetchAndStoreAsync(settings, () => webSiteApi.getSettingsAsync(params), store)
  const fetchUsersAsync = async(params, store = false) => useFetchAndStoreAsync(users, () => webSiteApi.getUsersAsync(params), store)

  const $reset = () => {
    setAuthorizationToken(null)
    stopRefreshTokenTimer()

    fabActions.value = []
    user.value = null
    accessToken.value = null
    refreshToken.value = null
    refreshTokenTimeout.value = null
    menuEntries.value = []
    users.value = []
  }

  const getDefaultSettings = () => (settings.value[0]?.data || {})

  const getDefaultCurrency = () => currencies.value.find(x => x.iso_code === 'EUR')
  const getUserInitials = () => {
    if (!user.value) {
      return ''
    }
    if (user.value.first_name && user.value.last_name) {
      return (user.value.first_name[0] + user.value.last_name[0]).toUpperCase()
    }
    const email = user.value.username.split('@')
    if (email[0].includes('.')) {
      const tmp = email[0].split('.')
      return tmp[0][0].toUpperCase() + tmp[1][0].toUpperCase()
    }
    return email[0].toUpperCase() + email[1].toUpperCase()
  }

  const getDecodedToken = () => {
    if (!accessToken.value) {
      return null
    }
    const jwtBase64 = accessToken.value.split('.')[1]
    return JSON.parse(atob(jwtBase64))
  }

  return {
    accessToken,
    collaborator,
    currencies,
    editedProjectId,
    eventSchedulerDate,
    fabActions,
    gridEditItemId,
    gridStates,
    menuEntries,
    refreshToken,
    refreshTokenTimeout,
    schedulerViews,
    settings,
    showEventVacations,
    showEventWarnings,
    sideBarPinned,
    sideBarExpanded,
    user,
    users,
    userTypes,
    $reset,
    activateUserAsync,
    autoLoginAsync,
    fetchCurrenciesAsync,
    fetchMenuEntriesAsync,
    fetchSettingsAsync,
    fetchUsersAsync,
    getDecodedToken,
    getDefaultCurrency,
    getDefaultSettings,
    getMenuEntriesWithRoute,
    getUserInitials,
    isAuthenticated,
    isValidToken,
    loginAsync,
    logoutAsync,
    refreshTokenAsync,
    routeToHomeAsync,
    setGridState,
    setTokenAsync,
    setUserAsync,
    startRefreshTokenTimerAsync,
    stopRefreshTokenTimer,
  }
}, {
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage, paths: [
          'accessToken',
          'editingMode',
          'gridStates',
          'menuEntries',
          'refreshToken',
          'sideBarPinned',
          'projects',
          'staffEvents',
          'equipment',
          'showEventWarnings',
          'showEventVacations',
          'settings',
        ]
      },
      { storage: sessionStorage, paths: ['gridEditItemId'] }
    ],
  },
})
