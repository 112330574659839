<script setup>

const props = defineProps({
  avatar: {
    type: String,
    required: false,
  },
  avatarIcon: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
  caption: {
    type: String,
    required: false,
  },
  chips: {
    type: Array,
    required: false,
  },
  color: {
    type: String,
    required: false,
  },
  captionChips: {
    type: Array,
    required: false,
  },
  icon: {
    type: String,
    required: false,
  },
  iconColor: {
    type: String,
    required: false,
  },
  secondaryIcon: {
    type: String,
    required: false,
  },
  secondaryIconColor: {
    type: String,
    required: false,
  },
  clickable: {
    type: Boolean,
    required: false,
  },
})

const emit = defineEmits(['click', 'iconClick', 'secondaryIconClick'])

</script>
<template>
  <q-item
    v-ripple
    :clickable="props.clickable"
    class="q-px-none"
    @click="emit('click')"
  >
    <q-item-section
      avatar
      class="align-middle align-center q-px-none"
    >
      <q-avatar>
        <q-img
          v-if="props.avatar"
          :src="props.avatar"
        />
        <q-icon
          v-else
          :name="props.avatarIcon || 'mdi-camera-off'"
          size="sm"
          color="grey-5"
        />
      </q-avatar>
    </q-item-section>
    <q-item-section
      style="flex-direction: column; justify-content: space-between; align-items: left;"
    >
      <q-item-label
        v-if="props.label"
        class="text-left"
      >
        <span :style="{color: props.color}">{{ props.label }}</span>
        <q-chip
          v-for="chip in props.chips"
          :key="chip"
          dense
          :label="chip.label || chip"
          :style="{ 'background-color': chip.color }"
        />
      </q-item-label>
      <q-item-label
        v-if="props.captionChips"
        caption
        class="text-left"
      >
        <q-chip
          v-for="chip in props.captionChips"
          :key="chip"
          size="sm"
          dense
          :label="chip.label || chip"
          :style="{ 'background-color': chip.color || 'grey-5' }"
        />
      </q-item-label>
    </q-item-section>
    <q-item-section
      v-if="props.secondaryIcon"
      avatar
      class="align-middle align-center q-px-none"
    >
      <q-icon
        :name="props.secondaryIcon"
        flat
        :color="props.secondaryIconColor || 'grey-5'"
        @click.stop="emit('secondaryIconClick')"
      />
    </q-item-section>
    <q-item-section
      v-if="props.icon"
      avatar
      class="align-middle align-center q-px-none"
    >
      <q-icon
        :name="props.icon"
        flat
        :color="props.iconColor || 'grey-5'"
        @click.stop="emit('iconClick')"
      />
    </q-item-section>
  </q-item>
</template>
