import { useI18n } from 'vue-i18n'

import { useWebSiteStore } from '@/stores/web-site.store'
import { usePlanningStore } from '@/stores/planning.store'

import { useCurrencyColumnFormatter } from '@/composables/data-grid'
import { useLookup, useCurrencyOptions } from '@/composables/data-grid'

import { useArchive } from '@/composables/archive'

export const useCurrencyColumn = (options) => {
  const i18n = useI18n()
  const webSiteStore = useWebSiteStore()

  const defaultOptions = {
    dataField: 'currency',
    referenceField: 'price',
    editors: {},
    lookup: useLookup({
      dataSource: webSiteStore.currencies,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    setCellValue: (newData, value, currentRowData) => {
      newData[options.dataField] = value
      const currentCurrency = webSiteStore.currencies.find((currency) => currency.id === value)
      if (currentCurrency) {
        if (options?.editors) {
          options.editors[options.referenceField]?.option('format', useCurrencyOptions({ currency: currentCurrency.iso_code }).format)
        }
      }
    },
    repaintChangesOnly: true,
  }

  options = { ...defaultOptions, ...options }

  return {
    caption: i18n.t('common.currency'),
    dataField: options.dataField,
    lookup: options.lookup,
    setCellValue: options.setCellValue,
  }
}

export const useCompanyColumn = (options) => {
  const i18n = useI18n()
  const planningStore = usePlanningStore()
  const { archiveDisplayExpr, archiveCellTemplate} = useArchive()

  const companies = options.companies || planningStore.companies

  return {
    dataField: 'company',
    caption: i18n.t('common.company'),
    lookup: useLookup({
      dataSource: companies,
      displayExpr: (i) => archiveDisplayExpr(i, 'name'),
      valueExpr: 'id',
    }),
    cellTemplate: (element, rowData) => archiveCellTemplate(element, rowData, planningStore.companies.find(i => i.id === rowData.data.company), 'name'),
    validationRules: [{ type: 'required' }]
  }
}

export const usePriceColumn = (options) => {
  const i18n = useI18n()
  const webSiteStore = useWebSiteStore()

  const defaultOptions = {
    caption: i18n.t('common.price'),
    dataField: 'price',
    dataType: 'number',
    referenceField: 'currency',
    lookup: useLookup({
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    setCellValue: (newData, value, currentRowData) => {
      newData[options.dataField] = value
      if (!currentRowData[options.referenceField]) {
        newData[options.referenceField] = webSiteStore.getDefaultCurrency()?.id
      }
    }
  }

  options = { ...defaultOptions, ...options }

  return {
    caption: options.caption,
    dataField: options.dataField,
    dataType: options.dataType,
    calculateDisplayValue: (rowData) => useCurrencyColumnFormatter(rowData, options.dataField, rowData[options.referenceField]),
    setCellValue: options.setCellValue,
  }
}

export const useArchivedAtColumn = (options) => {
  const i18n = useI18n()

  return {
    caption: i18n.t('common.archivedAt'),
    dataField: 'archived_at',
    dataType: 'date',
  }
}

export const useArchivedColumn = (options) => {
  const i18n = useI18n()

  return {
    caption: i18n.t('common.archived'),
    dataField: 'archived',
    dataType: 'boolean',
    width: '6rem',
  }
}
