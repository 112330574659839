<script setup>
import i18n from '@/plugins/i18n'
import dayjs from 'dayjs'

import { useDetectDevice, useOrientation } from '@/composables/device'
import { DxResource, DxScheduler } from 'devextreme-vue/scheduler'
import { computed, onMounted, ref, watch } from 'vue'

import { useODataSource } from '@/composables/data-grid'
import { usePlanningStore } from '@/stores/planning.store'
import { usePortalStore } from '@/stores/portal.store'
import { useScheduleStore } from '@/stores/schedule.store'
import { useWebSiteStore } from '@/stores/web-site.store'

import { getInitials, stringToColor } from '@/lib/utils.js'

import EventProjectStaffEquipmentToggle from '@/components/shared/EventProjectStaffEquipmentToggle.vue'
import EventVacationToggle from '@/components/shared/EventVacationToggle.vue'
import EventWarningToggle from '@/components/shared/EventWarningToggle.vue'
import ProjectCheckOutIn from '@/components/shared/ProjectCheckOutIn.vue'
import VacationDetail from '@/components/shared/VacationDetail.vue'
import VacationEditor from '@/components/shared/VacationEditor.vue'

import { scheduleApi } from '@/apis/schedule.api'

const t = i18n.global.t
const { isLandscape } = useOrientation()
const { isMobile } = useDetectDevice()
const dataSource = ref([])
const webSiteStore = useWebSiteStore()
const planningStore = usePlanningStore()
const portalStore = usePortalStore()
const scheduleStore = useScheduleStore()

const calendarViews = [
  {name: t('common.day'), type: 'day', intervalCount: 1, startDate: new Date(), maxAppointmentsPerCell: 'unlimited'},
  {name: `3 ${t('common.day', 2)}`, type: 'day', intervalCount: 3, startDate: new Date(), maxAppointmentsPerCell: 'unlimited'},
  {name: t('common.workWeek'), type: 'workWeek', intervalCount: 1, startDate: new Date(), maxAppointmentsPerCell: 'unlimited'},
  {name: t('common.week'), type: 'week', intervalCount: 1, startDate: new Date(), maxAppointmentsPerCell: 'unlimited'},
  {name: t('common.month'), type: 'month', intervalCount: 1, startDate: new Date(), maxAppointmentsPerCell: 'unlimited'}
]

const currentDate = ref(new Date().toISOString())
const currentView = ref(webSiteStore.schedulerViews.PROJECTS)
const currentCalendarView = ref(isMobile.value && !isLandscape.value ? calendarViews[1] : calendarViews[2])
const currentCalendarViewName = computed(() => currentCalendarView.value.name)
const editVacation = ref(null)
const equipmentDataSource = ref([])
const vacationDialog = ref(false)
const mounted = ref(false)
const projectsDataSource = ref([])
const scheduler = ref(null)
const selectedAppointment = ref(null)
const selectedEquipmentIds = ref([])
const selectedProjectIds = ref([])
const selectedStaffIds = ref([])
const staffDataSource = ref([])
const vacations = ref([])
const warnings = ref([])

const updateDataSourceAsync = async() => {
  dataSource.value = useODataSource(scheduleApi.getAbsoluteUrl() + scheduleApi.eventsUrl, {
    customParamsFn: () => {
      const params = {
        types: [scheduleStore.eventTypeEnum.PROJECT],
      }

      if (webSiteStore.showEventWarnings) {
        params.show_warnings = true
      }
      if (webSiteStore.showEventVacations && currentView.value !== webSiteStore.schedulerViews.EQUIPMENT) {
        params.show_vacations = true
      }

      if (currentView.value === webSiteStore.schedulerViews.PROJECTS) {
        params.service_type = portalStore.serviceTypeEnum.PROJECT
        params.project_ids = selectedProjectIds.value
        params.group_by_project = true
      }
      if (currentView.value === webSiteStore.schedulerViews.STAFF) {
        params.service_type = portalStore.serviceTypeEnum.STAFF
        params.staff_ids = selectedStaffIds.value
      }
      if (currentView.value === webSiteStore.schedulerViews.EQUIPMENT) {
        params.service_type = portalStore.serviceTypeEnum.EQUIPMENT
        params.equipment_ids = selectedEquipmentIds.value
      }
      return params
    },
    postProcess: (data) => {
      warnings.value = []
      return data.map(i => {
        if (webSiteStore.showEventWarnings) {
          i.warnings?.forEach(w => {
            if (!warnings.value.map(i => i.id).includes(w.id)) {
              warnings.value.push(w)
            }
          })
        }
        return {
          ...i,
          project: i.project_id === 0 ? planningStore.getVacationProject(t) : planningStore.getProjectById(i.project_id),
          staff: planningStore.getStaffById(i.staff_id),
          equipment: planningStore.getEquipmentById(i.equipment_id),
        }
      })
    },
  })
}

onMounted(async() => {
  if (webSiteStore.eventSchedulerDate) {
    currentDate.value = webSiteStore.eventSchedulerDate
    webSiteStore.eventSchedulerDate = null
  }

  await Promise.all([
    planningStore.fetchProjectsAsync({all: true}, true),
    planningStore.fetchStaffsAsync({all: true}, true),
    planningStore.fetchEquipmentsAsync({all: true}, true),
  ])
  const projects = planningStore.projects.map(i => {
    return {
      ...i,
      visible: true,
    }
  })
  projectsDataSource.value = [planningStore.getVacationProject(t), ...projects]
  selectedProjectIds.value = projectsDataSource.value.map(i => i.id)

  staffDataSource.value = planningStore.staff?.map(i => {
    return {
      ...i,
      color: stringToColor(i.initials),
      visible: true,
    }
  })
  selectedStaffIds.value = staffDataSource.value.map(i => i.id)

  equipmentDataSource.value = planningStore.equipments?.map(i => {
    return {
      ...i,
      color: stringToColor(getInitials(i.name)),
      visible: true,
    }
  })
  selectedEquipmentIds.value = equipmentDataSource.value.map(i => i.id)

  await updateDataSourceAsync()
  mounted.value = true
})

webSiteStore.fabActions = [
  {
    id: 'add',
    icon: 'mdi-calendar-plus',
    onClick: () => {
      vacationDialog.value = true
    },
  },
]

const onAppointmentClick = async(e) => {
  if (e.appointmentData.warnings?.length) {
    let eventWarnings = (await scheduleStore.fetchEventWarningsAsync({project_id: e.appointmentData.project_id})).data
    eventWarnings = eventWarnings.filter(i => e.appointmentData.warnings.map(i => i.id).includes(i.id))
    e.appointmentData.warnings = eventWarnings
  }
  selectedAppointment.value = e.appointmentData
}

const onVacationInserting = (e) => {
  e.cancel = true
  editVacation.value = {
    start: dayjs(e.appointmentData.start).toISOString(),
    end: dayjs(e.appointmentData.end).toISOString(),
    service: webSiteStore.user.service_id,
  }
  vacationDialog.value = true
}

const hideVacationDialog = () => {
  editVacation.value = null
  vacationDialog.value = false
}

const onVacationUpdate = async() => {
  await updateDataSourceAsync()
  hideVacationDialog()
}

const onChange = async() => {
  selectedAppointment.value = null
  await updateDataSourceAsync()
}

const onProjectCheckOutInChange = async() => {
  await planningStore.fetchProjectsAsync({all: true}, true)
  await onChange()
}

const onServiceViewChange = async(value) => {
  currentView.value = value
  await updateDataSourceAsync(value)
}

const onServiceSelectionChange = async(value) => {
  if (currentView.value === webSiteStore.schedulerViews.PROJECTS) {
    selectedProjectIds.value = value
  } else if (currentView.value === webSiteStore.schedulerViews.STAFF) {
    selectedStaffIds.value = value
  } else if (currentView.value === webSiteStore.schedulerViews.EQUIPMENT) {
    selectedEquipmentIds.value = value
  }
  await updateDataSourceAsync()
}

const onOptionChanged = (e) => {
  if (e.name === 'currentView') {
    currentCalendarView.value = calendarViews.find(i => i.name === e.value)
  }
}

let startX = 0
let startY = 0

document.addEventListener('touchstart', function(e) {
  if (e.touches.length === 2) {
    swipeArrow.style.display = 'none'
  } else {
    const swipeArrow = document.getElementById('swipeArrow')
    startX = e.touches[0].clientX
    startY = e.touches[0].clientY
    swipeArrow.style.display = 'block'
  }
})

const getDistance = (touch1, touch2) => {
  const dx = touch2.clientX - touch1.clientX
  const dy = touch2.clientY - touch1.clientY
  return Math.sqrt(dx * dx + dy * dy)
}

if (isMobile.value) {
  let initialDistance = null
  let delta = 0
  let scale = 1
  const maxScale = 2.5
  const minScale = 0.75
  const currentHeight = 40
  let scaleChanged = false

  const updateGlobalVariable = (newHeight) => {
    document.documentElement.style.setProperty('--scheduler-cell-height', `${newHeight}px`)
    scaleChanged = true
  }

  updateGlobalVariable(currentHeight)

  document.addEventListener('touchmove', function(e) {
    if (e.touches.length === 2) {
      const distance = getDistance(e.touches[0], e.touches[1])
      if (initialDistance === null) {
        initialDistance = distance
      } else {
        const scaleChange = distance / initialDistance
        scale = Math.max(minScale, Math.min(maxScale, scale * scaleChange))
        console.log(scale)

        updateGlobalVariable(currentHeight * scale)

        initialDistance = distance
      }
      console.log(scale)
    } else {
      const swipeArrow = document.getElementById('swipeArrow')
      const deltaY = e.touches[0].clientY - startY

      if (Math.abs(deltaY) > 20) {
        swipeArrow.style.display = 'none'
        return
      }

      delta = e.touches[0].clientX - startX
      const deltaPercentage = Math.abs(delta) / window.innerWidth
      const limitedDelta = deltaPercentage * (window.innerWidth * 0.2)
      swipeArrow.style.opacity = 1 / (deltaPercentage / 2)

      if (delta > 20) {
        swipeArrow.style.left = `${Math.min(limitedDelta, window.innerWidth * 0.3)}px`
        swipeArrow.style.transform = `rotate(${Math.max(Math.min(-45, -90 * (deltaPercentage * 2)), -90)}deg)`
      }
      if (delta < -20) {
        swipeArrow.style.left = `${Math.max(window.innerWidth - limitedDelta, window.innerWidth * 0.7)}px`
        swipeArrow.style.transform = `rotate(${Math.min(Math.max(45, 90 * (deltaPercentage * 2)), 90)}deg)`
      }
    }
  }, { passive: false })

  document.addEventListener('touchend', function(event) {
    const swipeArrow = document.getElementById('swipeArrow')
    swipeArrow.style.opacity = '0'

    const endX = event.changedTouches[0].clientX
    const endY = event.changedTouches[0].clientY

    const diffX = endX - startX
    const diffY = endY - startY

    if (Math.abs(diffX) > Math.abs(diffY)) {
      const type = currentCalendarView.value.name === calendarViews[2].name ? calendarViews[3].type : currentCalendarView.value.type
      if (diffX > 50) {
        currentDate.value = dayjs(currentDate.value).subtract(currentCalendarView.value.intervalCount, type).toISOString()
      } else if (diffX < -50) {
        currentDate.value = dayjs(currentDate.value).add(currentCalendarView.value.intervalCount, type).toISOString()
      }
    }
    initialDistance = null

    if (scaleChanged) {
      scaleChanged = false
      scheduler.value.instance.repaint()
    }
  }, { passive: true })
}

watch(() => webSiteStore.sideBarExpanded, (value, oldValue) => {
  if (value !== oldValue) {
    scheduler.value.instance.repaint()
  }
})

</script>
<template>
  <div>
    <q-btn
      id="swipeArrow"
      dense
      round
      name="mdi-chevron-left"
      icon="mdi-chevron-up"
    />
    <q-dialog
      v-model="vacationDialog"
      :full-width="isMobile"
    >
      <vacation-editor
        :model-value="editVacation"
        @update="onVacationUpdate"
        @cancel="hideVacationDialog"
      />
    </q-dialog>
    <q-dialog
      :model-value="selectedAppointment !== null"
      :full-width="isMobile"
      @hide="selectedAppointment = null"
    >
      <project-check-out-in
        v-if="selectedAppointment.project_id !== 0"
        :model-value="selectedAppointment.project"
        :warnings="selectedAppointment.warnings"
        @update="onProjectCheckOutInChange"
      />
      <vacation-detail
        v-else
        :model-value="selectedAppointment"
        @update="onChange"
      />
    </q-dialog>
    <div style="display: flex;">
      <event-project-staff-equipment-toggle
        v-if="mounted"
        :view="currentView"
        :projects-data-source="projectsDataSource"
        :staff-data-source="staffDataSource"
        :equipment-data-source="equipmentDataSource"
        @view-change="onServiceViewChange"
        @selection-change="onServiceSelectionChange"
      />
      <q-space />
      <event-vacation-toggle
        v-if="mounted && currentView !== webSiteStore.schedulerViews.EQUIPMENT"
        v-model="webSiteStore.showEventVacations"
        :vacations="vacations"
        @update:model-value="updateDataSourceAsync"
      />
      <event-warning-toggle
        v-model="webSiteStore.showEventWarnings"
        :warnings="warnings"
        @update:model-value="updateDataSourceAsync"
      />
    </div>
    <div
      style="height: calc(100vh - 10rem); overflow: hidden;"
    >
      <DxScheduler
        id="scheduler"
        ref="scheduler"
        show-current-time-indicator
        shade-until-current-time
        all-day-panel-mode="hidden"
        text-expr="project.name"
        start-date-expr="start"
        end-date-expr="end"
        key-expr="id"
        :start-day-hour="!isMobile ? 0 : isLandscape ? 6 : 0"
        :remote-filtering="true"
        :data-source="dataSource"
        :current-date="currentDate"
        :views="calendarViews"
        :cell-duration="60"
        :current-view="currentCalendarViewName"
        :height="!isMobile ? '100%' : isLandscape ? 'calc(100vh - 5rem)' : 'calc(100vh - 5rem)'"
        :first-day-of-week="1"
        :editing="{ allowAdding: true, allowDeleting: true, allowUpdating: true, allowDragging: false, allowResizing: currentView !== webSiteStore.schedulerViews.PROJECTS }"
        appointment-template="AppointmentTemplateSlot"
        @option-changed="onOptionChanged"
        @appointment-click="(e)=>{e.cancel = true;}"
        @appointment-form-opening="onVacationInserting"
      >
        <template #AppointmentTemplateSlot="{ data }">
          <div
            :id="data.appointmentData.id"
            style="display: flex; flex-direction: column; align-items: flex-start;"
            :class="{
              'event': true,
              'event-warning': data.appointmentData.warnings?.length > 0,
              'event-vacation': data.appointmentData.project_id === 0,
              'event-project-production': data.appointmentData.project?.status === planningStore.projectStatusEnum.PRODUCTION,
            }"
            @click="(e)=>{ e.stopPropagation(); onAppointmentClick(data) }"
          >
            <q-item-label class="q-mb-xs text-bold q-ml-sm q-mt-xs">
              {{ data.appointmentData.project?.name }}
              <q-icon
                v-if="data.appointmentData.project_id === 0"
                name="mdi-beach"
                size="xs"
                class="q-mb-sm"
              />
            </q-item-label>
            <div
              v-if="data.appointmentData.warnings?.length"
              class="q-mb-sm text-bold q-ml-sm q-mt-sm"
            >
              <q-item-label
                v-for="warning in data.appointmentData.warnings"
                :key="warning"
                caption
              >
                <q-icon
                  name="mdi-alert"
                  size="xs"
                />
                {{ t(`warning.event.${planningStore.getEventWarningTypeName(warning.type)}`) }}
              </q-item-label>
            </div>
            <q-chip
              v-if="data.appointmentData.project_id !== 0"
              text-color="white"
              size="sm"
              :label="planningStore.getLocalizedProjectStatuses()[data.appointmentData.project?.status].name"
              :color="planningStore.getProjectStatusColor(data.appointmentData.project?.status)"
            />
            <q-item-label
              v-if="currentView !== webSiteStore.schedulerViews.PROJECTS || data.appointmentData.project_id === 0"
              class="q-ml-sm"
              caption
            >
              {{ planningStore.getServiceName(data.appointmentData?.staff, true) }}
            </q-item-label>
            <q-item-label
              v-if="currentView !== webSiteStore.schedulerViews.PROJECTS"
              class="q-ml-sm"
              caption
            >
              {{ data.appointmentData.equipment?.name }}
            </q-item-label>
          </div>
        </template>
        <DxResource
          :data-source="projectsDataSource"
          :allow-multiple="true"
          :use-color-as-default="currentView === webSiteStore.schedulerViews.PROJECTS"
          field-expr="project_id"
          label="Project"
          display-expr="name"
        />
        <DxResource
          :data-source="staffDataSource"
          :allow-multiple="true"
          :use-color-as-default="currentView === webSiteStore.schedulerViews.STAFF"
          field-expr="staff_id"
          label="Staff"
          display-expr="name"
        />
        <DxResource
          :data-source="equipmentDataSource"
          :use-color-as-default="currentView === webSiteStore.schedulerViews.EQUIPMENT"
          :allow-multiple="true"
          field-expr="equipment_id"
          label="Equipment"
          display-expr="name"
        />
      </DxScheduler>
    </div>
  </div>
</template>
<style lang="scss">
@import '@/styles/quasar.variables.scss';

.event {
  color: $white;
  & .text-caption {
    color: $white;
  }
}

.event-project-production {
  border-left: 4px solid $positive;
}

.event-warning {
  background-color: $warning;
  margin-left: 3px;
}

.event-vacation {

  & .text-caption {
    color: $primary;
  }
  background-image:
  repeating-linear-gradient(
      135deg,
      rgb(255, 255, 255),
      rgb(255, 255, 255) 4px,
      lighten($primary, 50) 4px,
      lighten($primary, 50) 9px
    );
  border: 2px solid lighten($primary, 20);
  border-left: 4px solid lighten($primary, 20);
  background-color: lighten($primary, 30);
  color: $primary;
}

.dx-scheduler-date-table-cell{
  height: 3rem;
}
.dx-scheduler-date-table-cell{
  height: 3rem;
}
#swipeArrow {
  background-color: $white;
  transition: opacity 1s ease;
  position: fixed; top: 50%;
  transform: translateY(-50%);
  display: none; opacity: 0;
   z-index: 10000;
}

:root {
  --scheduler-cell-height: 40px;
}

.dx-scheduler-cell-sizes-vertical {
  height: var(--scheduler-cell-height) !important;
}
</style>
