<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { DxTagBox } from 'devextreme-vue/tag-box'
import { DxItem } from 'devextreme-vue/form'
import { DxTabPanel } from 'devextreme-vue/tab-panel'

import { useGridRef, useODataSource, useLookup, useDefaultEditing, useDefaultGridOptions, useDefaultDialogTitle, useCurrencyOptions } from '@/composables/data-grid'
import { useArchivedAtColumn, useArchivedColumn, useCurrencyColumn, usePriceColumn } from '@/composables/data-grid-column'
import { useArchive } from '@/composables/archive'

import { planningApi } from '@/apis/planning.api'
import { usePortalStore } from '@/stores/portal.store'
import { usePlanningStore } from '@/stores/planning.store'
import { useWebSiteStore } from '@/stores/web-site.store'

import ArchiveGridButton from '@/components/shared/ArchiveGridButton.vue'
import ArchivedGridToggle from '@/components/shared/ArchivedGridToggle.vue'
import ArchiveWarningDialog from '@/components/shared/ArchiveWarningDialog.vue'
import TagChipsTemplate from '@/components/shared/ChipsTemplate.vue'

import ServiceAvailabilityGrid from './ServiceAvailabilityGrid.vue'
import EquipmentsGridDetail from './EquipmentsGridDetail.vue'

import { stringToRgb } from '@/lib/utils'

const gridId = 'equipments-grid'

const i18n = useI18n()
const t = i18n.t
const portalStore = usePortalStore()
const planningStore = usePlanningStore()
const webSiteStore = useWebSiteStore()

const localizedTags = portalStore.getLocalizedTags().filter(i => i.type === planningStore.serviceTypeEnum.EQUIPMENT)
const localizedServiceStatuses = portalStore.getLocalizedServiceStatuses()
const localizedServiceAvailabilityTypes = portalStore.getLocalizedServiceAvailabilityTypes()
const printQrCodeToolbarButton = ref(null)
const selectedRows = ref([])
const editors = {}

const { gridRef } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()

const gridDataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.equipmentsUrl, {
  customParamsFn: () => showArchived.value ? { all: true } : {},
})
const { archiveWarning, archiveButton, showArchived, archiveAsync, archiveDisplayExpr, archiveCellTemplate, onRowPrepared, onRowRemoving } = useArchive({
  onArchive: async(id) => { await planningStore.archiveEquipmentAsync(id) },
  onDelete: async(id, config) => { await planningStore.deleteEquipmentAsync(id, config) },
  gridRef: gridRef,
})

const fetchRentalCompanies = async() => (await planningStore.fetchCompaniesAsync({all: true}, true))?.filter(i => i.is_rental)
let rentalCompanies = await fetchRentalCompanies()

const columns = [
  {
    type: 'buttons',
    buttons: [
      { name: 'edit', visible: (e) => !e.row?.data?.archived },
      { name: 'delete', visible: (e) => !e.row?.data?.archived },
      archiveButton,
    ],
    fixed: false,
    visibleIndex: -1,
    width: '6rem',
  },
  { dataField: 'name', caption: i18n.t('common.name'), validationRules: [{ type: 'required' }], sortOrder: 'asc' },
  { dataField: 'description', caption: i18n.t('common.description') },
  {
    dataField: 'tags',
    caption: i18n.t('common.tag', 2),
    lookup: useLookup({
      dataSource: localizedTags,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    setCellValue: (newData, value) => (newData.tags = value),
    cellTemplate: 'tag-cell-template',
    editCellTemplate: 'tag-edit-cell-template',
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'availability_type',
    caption: i18n.t('common.availabilityType'),
    lookup: useLookup({
      dataSource: localizedServiceAvailabilityTypes,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    cellTemplate: 'availability-type-cell-template',
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'condition_value',
    caption: i18n.t('common.condition'),
    lookup: useLookup({
      dataSource: planningStore.getLocalizedEquipmentConditions(),
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    cellTemplate: 'condition-cell-template',
  },
  {
    dataField: 'condition_comment',
    caption: i18n.t('common.conditionComment'),
  },
  {
    dataField: 'status',
    caption: i18n.t('common.status'),
    lookup: useLookup({
      dataSource: localizedServiceStatuses,
      valueExpr: 'id',
      displayExpr: 'name',
    }),
    cellTemplate: 'status-cell-template',
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'is_rented',
    dataType: 'boolean',
    caption: i18n.t('common.rented'),
    cellTemplate: 'rented-cell-template',
  },
  usePriceColumn({ dataField: 'buy_price', referenceField: 'buy_price_currency' }),
  useCurrencyColumn({ dataField: 'buy_price_currency', referenceField: 'buy_price', editors: editors }),
  {
    dataField: 'buy_date',
    caption: i18n.t('common.buyDate'),
    dataType: 'date',
  },
  { dataField: 'need_qr_scan', caption: i18n.t('common.needQrScan'), dataType: 'boolean' },
  { dataField: 'uuid', caption: i18n.t('common.uuid'), visible: false, formItem: { visible: false } },
  {
    dataField: 'rental_company_name', caption: i18n.t('common.rentalCompany.name'),
    cellTemplate: (element, rowData) => archiveCellTemplate(element, rowData, rowData.data.rental_company, 'name'),
  },
  {
    dataField: 'rental_company.id',
    lookup: useLookup({
      dataSource: () => rentalCompanies,
      valueExpr: 'id',
      displayExpr: (i) => archiveDisplayExpr(i, 'name'),
    }, {

      acceptCustomValue: true,
    }),
    setCellValue: (newData, value, currentData) => {
      const currentCompany = rentalCompanies.find(i => i.id === value)
      if (currentCompany) {
        newData.rental_company = {
          id: currentCompany?.id,
          name: currentCompany?.name,
          contact: currentCompany?.contact,
        }
      } else if (value === null) {
        newData.rental_company = null
      }
      if (value) {
        newData.availability_type = portalStore.serviceAvailabilityTypeEnum.USUALLY_UNAVAILABLE
        newData.buy_date = null
      }

      editors['availability_type'].option('readOnly', !!value)
      editors['buy_date'].option('readOnly', !!value)
      editors['rental_company.contact'].option('readOnly', !value)
    },
    caption: i18n.t('common.rentalCompany.name'),
    visible: false,
  },
  {
    dataField: 'rental_company.contact', caption: i18n.t('common.rentalCompany.contact'),
    cellTemplate: (element, rowData) => archiveCellTemplate(element, rowData, rowData.data.rental_company, 'contact'),
  },
  useArchivedAtColumn(),
  useArchivedColumn(),
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.equipment')), {
  colCount: 4,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 4,
      colSpan: 3,
      items: [
        { dataField: 'name' },
        { dataField: 'description' },
        { dataField: 'tags', colSpan: 2 },
        { dataField: 'availability_type' },
        { dataField: 'status' },
      ]
    },
    {
      itemType: 'group',
      caption: i18n.t('common.rentalInfo'),
      colCount: 2,
      items: [
        { dataField: 'rental_company.id', colSpan: 2, editorOptions: {
          showClearButton: true, acceptCustomValue: true, onCustomItemCreating: (args) => {
            const newCompany = {
              id: -1,
              name: args.text,
              contact: null,
              is_rental: true,
            }
            rentalCompanies.push(newCompany)
            return newCompany
          } },
        },
        { dataField: 'rental_company.contact', colSpan: 2 },
      ]
    },
    {
      itemType: 'group',
      caption: i18n.t('common.additionalInfo'),
      colCount: 4,
      colSpan: 3,
      items: [
        { dataField: 'buy_price', editorOptions: useCurrencyOptions() },
        { dataField: 'buy_price_currency'},
        { dataField: 'buy_date' },
        { dataField: 'need_qr_scan' },
      ]
    },
    {
      itemType: 'group',
      caption: i18n.t('common.conditionInfo'),
      colCount: 2,
      items: [
        { dataField: 'condition_value', colSpan: 2 },
        { dataField: 'condition_comment', colSpan: 2, editor_type: 'dxTextArea' },
      ]
    },
  ],
})

const onRowInserting = async(e) => {
  if (e.data.rental_company?.id < 0) {
    e.data.rental_company.id = null
    e.data.rental_company.is_rental = true
    e.data.rental_company.is_client = false
  }
  e.data.type = planningStore.serviceTypeEnum.EQUIPMENT
}

const onRowUpdating = async(e) => {
  if (e.newData.rental_company?.id < 0) {
    e.newData.rental_company.id = null
    e.newData.rental_company.is_rental = true
    e.newData.rental_company.is_client = false
  }
}

const onSaved = async(e) => {
  rentalCompanies = await fetchRentalCompanies()
  gridRef.value.instance.refresh()
}

const onSelectionChanged = (e) => {
  selectedRows.value = e.selectedRowsData
  printQrCodeToolbarButton.value.option('disabled', e.selectedRowsData.length === 0)
}

const onPrintQrCodeClick = async() => {
  const data = selectedRows.value.map(row => row.id)
  await planningApi.downloadEquipmentQrCodesAsync(`${i18n.t('common.equipment', 2)}.pdf`, data)
  selectedRows.value = []
  gridRef.value.instance.clearSelection()
  await gridRef.value.instance.refresh()
}

const onToolbarPreparing = (e) => {
  e.toolbarOptions.items.unshift(
    {
      widget: 'dxButton',
      location: 'after',
      options: {
        icon: 'mdi mdi-qrcode',
        disabled: true,
        text: i18n.t('common.printQrCode'),
        onClick: onPrintQrCodeClick,
        onInitialized: (e) => { printQrCodeToolbarButton.value = e.component }
      }
    },
  )
}

const onInitNewRow = (e) => {
  e.data.buy_price_currency = webSiteStore.getDefaultCurrency()?.id
}

const onEditorPreparing = (e) => {
  if (e.parentType === 'dataRow' && e.dataField === 'buy_price') {
    e.editorOptions.onInitialized = (e) => { editors['buy_price'] = e.component }
    const currentCurrency = webSiteStore.currencies.find(i => i.id === e.row.data.buy_price_currency)
    if (currentCurrency) {
      e.editorOptions.format = useCurrencyOptions({currency: currentCurrency.iso_code})?.format
    }
  }
  if (e.parentType === 'dataRow' && e.dataField === 'availability_type') {
    e.editorOptions.onInitialized = (e) => { editors['availability_type'] = e.component }
    e.editorOptions.readOnly = !!e.row.data.rental_company?.id
  }
  if (e.parentType === 'dataRow' && e.dataField === 'buy_date') {
    e.editorOptions.onInitialized = (e) => { editors['buy_date'] = e.component }
    e.editorOptions.readOnly = !!e.row.data.rental_company?.id
  }
  if (e.parentType === 'dataRow' && e.dataField === 'rental_company.contact') {
    e.editorOptions.onInitialized = (e) => { editors['rental_company.contact'] = e.component }
    e.editorOptions.readOnly = !e.row.data.rental_company?.id
  }
}

const onGridTabSelectionChanged = (e) => {
  gridRef.value.instance.updateDimensions()
}

const gridOptions = useDefaultGridOptions({
  id: gridId,
  columns: columns,
  dataSource: gridDataSource,
  editing: editing,
  masterDetail: {
    enabled: true,
    template: 'master-detail',
  },
  onInitNewRow: onInitNewRow,
  onEditorPreparing: onEditorPreparing,
  onRowInserting: onRowInserting,
  onRowUpdating: onRowUpdating,
  onSaved: onSaved,
  onSelectionChanged: onSelectionChanged,
  onToolbarPreparing: onToolbarPreparing,
  onRowPrepared: onRowPrepared,
  onRowRemoving: onRowRemoving,
  selection: { mode: 'multiple', showCheckBoxesMode: 'onClick', selectAllMode: 'page' },
  clearFilterButton: true,
  archivedToggle: true,
})

const getServiceChips = (tags) => {
  return tags.map(tag => ({
    id: tag,
    label: localizedTags.find(t => t.id === tag).name,
  }))
}

</script>
<template>
  <div>
    <archive-warning-dialog
      v-model="archiveWarning"
      @confirm="async() => await archiveAsync(archiveWarning.item.id, false)"
    />
    <dx-data-grid
      ref="gridRef"
      v-bind="gridOptions"
    >
      <template #master-detail="{ data: templateData }">
        <dx-tab-panel @selection-changed="onGridTabSelectionChanged">
          <dx-item
            :title="$t('common.detail', 2)"
            template="details-tab"
          />
          <template #details-tab>
            <equipments-grid-detail :equipment="templateData.data" />
          </template>
          <dx-item
            :title="$t('common.availability')"
            template="availability-tab"
          />
          <template #availability-tab>
            <service-availability-grid :data="templateData.data" />
          </template>
        </dx-tab-panel>
      </template>
      <template #tag-cell-template="{ data: templateOptions }">
        <tag-chips-template :tags="getServiceChips(templateOptions.data.tags)" />
      </template>
      <template #tag-edit-cell-template="{ data: templateOptions }">
        <dx-tag-box
          :value="templateOptions.data.tags"
          :data-source="localizedTags"
          tag-template="tag-template"
          value-expr="id"
          display-expr="name"
          @value-changed="(e) => templateOptions.setValue(e.value)"
        >
          <template #tag-template="{ data: innerData }">
            <div
              class="dx-tag-content"
              :style="{ backgroundColor: `rgb(${stringToRgb(innerData.name).join(',')})` }"
            >
              <div class="dx-tag-remove-button" />
              <div class="dx-tag-text">
                {{ innerData.name }}
              </div>
            </div>
          </template>
        </dx-tag-box>
      </template>
      <template #status-cell-template="{ data: templateOptions }">
        <q-chip
          size="sm"
          :label="localizedServiceStatuses.find(s => s.id === templateOptions.data.status).name"
          :color="templateOptions.data.status === 0 ? 'green' : templateOptions.data.status === 1 ? 'orange' : 'yellow'"
        />
      </template>
      <template #rented-cell-template="{ data: templateOptions }">
        <q-chip
          v-if="templateOptions.data.is_rented"
          size="sm"
          :label="t('common.rented')"
          color="teal"
        />
      </template>
      <template #availability-type-cell-template="{ data: templateOptions }">
        <q-chip
          size="sm"
          :label="localizedServiceAvailabilityTypes.find(s => s.id === templateOptions.data.availability_type).name"
          :color="templateOptions.data.availability_type === 0 ? 'green' : templateOptions.data.availability_type === 1 ? 'green' : 'yellow'"
        />
      </template>
      <template #condition-cell-template="{ data: templateOptions }">
        <q-chip
          v-if="templateOptions.data.condition_value"
          size="sm"
          :label="planningStore.getConditionLabel(templateOptions.data.condition_value)"
          :color="planningStore.getConditionColor(templateOptions.data.condition_value)"
        />
      </template>
      <template #archive-button="{ data: row }">
        <archive-grid-button :row="row" />
      </template>
      <template #archived-toggle>
        <archived-grid-toggle
          :show-archived="showArchived"
          :grid-ref="gridRef"
          @update:show-archived="showArchived = $event"
        />
      </template>
    </dx-data-grid>
  </div>
</template>
