<script setup>

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: false,
  },
  iconDisabled: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const emit = defineEmits(['click', 'back'])

</script>
<template>
  <q-item class="q-pa-none">
    <q-item-section
      avatar
      class="align-middle align-center q-pa-none"
    >
      <q-icon
        name="mdi-arrow-left"
        class="cursor-pointer"
        @click="emit('back')"
      />
    </q-item-section>
    <q-item-section>
      <q-item-label class="text-left text-h6">
        {{ props.text }}
      </q-item-label>
    </q-item-section>
    <q-item-section
      v-if="props.icon"
      avatar
      class="align-middle align-center q-pa-none"
    >
      <q-btn
        flat
        round
        :icon="props.icon"
        :disable="props.iconDisabled"
        :color="props.iconDisabled ? 'grey' : null"
        @click="emit('click')"
      />
    </q-item-section>
  </q-item>
</template>
<style scoped>
  .text {
    display: flex;
    align-items: center;
  }
</style>
