import httpApi from './http.api'

export default class BaseApi {
  constructor(url) {
    this.url = url
  }

  encodeQueryData(data) {
    const ret = []
    for (const d in data) {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
    }
    return ret.join('&')
  }

  getUrlWithParams(url, parameters) {
    var querystring = this.encodeQueryData(parameters)
    if (!url.endsWith('/')) {
      url += '/'
    }
    return `${url}?${querystring}`
  }

  getAbsoluteUrl(url = this.url) {
    return `${this.getBaseApi()}${url}`
  }

  getBaseApi() {
    return `${window.config.BASE_API}`
  }

  getBaseMediaUrl() {
    return `${window.config.BASE_MEDIA}`
  }

  composeUrl(...parts) {
    const urlSeparator = '/'
    parts = parts.map(p => p ? p.toString() : '')
    const res = parts.filter(p => p.length > 0).map(p => {
      if (p.charAt(0) === urlSeparator) p = p.substr(1)
      if (p.charAt(p.length - 1) === urlSeparator) p = p.substr(0, p.length - 1)
      return p
    }).join(urlSeparator) + urlSeparator

    return res
  }

  downloadAsync(url, fileName, params = null, config = {}) {
    const downloadUrl = this.composeUrl(this.url, url)
    params = params || {}
    return httpApi({
      url: downloadUrl,
      method: 'get',
      params: params,
      responseType: 'blob',
      ...config
    }).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    })
  }

  getAsync(url, params, config) {
    return httpApi({
      url: this.composeUrl(this.url, url),
      method: 'get',
      params: params,
      ...config
    })
  }

  postAsync(url, data, config) {
    return httpApi({
      url: this.composeUrl(this.url, url),
      method: 'post',
      data: data,
      ...config
    })
  }

  putAsync(url, data, config) {
    return httpApi({
      url: this.composeUrl(this.url, url),
      method: 'put',
      data: data,
      ...config
    })
  }

  patchAsync(url, data, config) {
    return httpApi({
      url: this.composeUrl(this.url, url),
      method: 'patch',
      data: data,
      ...config
    })
  }

  upsertAsync(url, data, config) {
    return data.id ? this.patchAsync(`${url}${data.id}/`, data, config) : this.postAsync(url, data, config)
  }

  deleteAsync(url, config) {
    return httpApi({
      url: this.composeUrl(this.url, url),
      method: 'delete',
      ...config
    })
  }

  getLastUpdate() {
    return this.callGet('/last-update')
  }
}
