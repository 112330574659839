<script setup>
import dayjs from 'dayjs'

import { useI18n } from 'vue-i18n'
import { DxDataGrid } from 'devextreme-vue/data-grid'
import { DxColorBox } from 'devextreme-vue/color-box'
import { useGridRef, useODataSource, useLookup, useDefaultEditing, useDefaultOnContentReady, useDefaultGridOptions, useDefaultDialogTitle } from '@/composables/data-grid'
import { useArchivedAtColumn, useArchivedColumn } from '@/composables/data-grid-column'
import { useArchive } from '@/composables/archive'

import { planningApi } from '@/apis/planning.api'
import { usePlanningStore } from '@/stores/planning.store'
import { useWebSiteStore } from '@/stores/web-site.store'

import FileUploader from '@/components/shared/FileUploader.vue'
import ArchiveGridButton from '@/components/shared/ArchiveGridButton.vue'
import ArchivedGridToggle from '@/components/shared/ArchivedGridToggle.vue'
import ArchiveWarningDialog from '@/components/shared/ArchiveWarningDialog.vue'

import ProjectListingItemsGrid from './ProjectListingItemsGrid.vue'

const gridId = 'projects-grid'

const i18n = useI18n()
const planningStore = usePlanningStore()
const webSiteStore = useWebSiteStore()

const { gridRef } = useGridRef()
const { defaultDialogTitle } = useDefaultDialogTitle()
const { archiveWarning, showArchived, archiveButton, archiveAsync, archiveDisplayExpr, archiveCellTemplate, onRowPrepared, onRowRemoving } = useArchive({
  onArchive: async(id) => { await planningStore.archiveProjectAsync(id) },
  onDelete: async(id, config) => { await planningStore.deleteProjectAsync(id, config) },
  gridRef: gridRef,
})
const editors = {}

const gridDataSource = useODataSource(planningApi.getAbsoluteUrl() + planningApi.projectsUrl, {
  customParamsFn: () => { return showArchived.value ? { all: true } : {} }
})

const onContentReady = (e) => {
  useDefaultOnContentReady(e)
  if (webSiteStore.editedProjectId) {
    const projectName = planningStore.projects.find(i => i.id === webSiteStore.editedProjectId)?.name
    gridRef.value.instance.columnOption('name', 'filterValue', projectName)
    gridRef.value.instance.expandRow(webSiteStore.editedProjectId)
    webSiteStore.editedProjectId = null
  }
}

const columns = [
  {
    type: 'buttons',
    buttons: [
      { name: 'edit', visible: (e) => !e.row?.data?.archived },
      { name: 'delete', visible: (e) => !e.row?.data?.archived },
      archiveButton,
    ],
    fixed: false,
    visibleIndex: -1,
    width: '6rem',
  },
  {
    dataField: 'color', caption: i18n.t('common.color'),
    editCellTemplate: 'colorPicker',
    showInColumnChooser: false,
    setCellValue: (newData, value) => {
      newData.color = value
    },
    visible: false,
  },
  {
    dataField: 'name',
    caption: i18n.t('common.name'),
    cellTemplate: 'name-cell-template',
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'start', caption: i18n.t('common.start'),
    dataType: 'datetime',
    sortOrder: 'desc',
    validationRules: [{ type: 'required' }]
  },
  {
    dataField: 'end',
    caption: i18n.t('common.end'),
    dataType: 'datetime',
    validationRules: [{ type: 'required' }]
  },
  { dataField: 'description', caption: i18n.t('common.description') },
  {
    dataField: 'listing', caption: i18n.t('common.listing'),
    lookup: useLookup({
      dataSource: {
        store: planningStore.listings,
      },
      displayExpr: (i) => archiveDisplayExpr(i, 'name'),
    }),
    cellTemplate: (element, rowData) => archiveCellTemplate(element, rowData, planningStore.listings.find(i => i.id === rowData.data.listing), 'name'),
    validationRules: [{ type: 'required' }]
  },
  { dataField: 'journalist', caption: i18n.t('common.journalist') },
  { dataField: 'location', caption: i18n.t('common.location') },
  { dataField: 'project_files', caption: i18n.t('common.attachment', 2), editCellTemplate: 'fileUploader', visible: false, showInColumnChooser: false },
  {
    dataField: 'status', caption: i18n.t('common.status'),
    lookup: useLookup({store: planningStore.getLocalizedProjectStatuses(), displayExpr: 'name' }),
    cellTemplate: 'status-cell-template',
  },
  useArchivedAtColumn(),
  useArchivedColumn(),
]

const editing = useDefaultEditing(i => defaultDialogTitle(i, i18n.t('common.project')), {
  colCount: 2,
  items: [
    {
      itemType: 'group',
      caption: i18n.t('common.mainInfo'),
      colCount: 3,
      items: [
        { dataField: 'name' },
        { dataField: 'description', colSpan: 2 },
        { dataField: 'start', editorType: 'dxDateBox', editorOptions: { type: 'datetime' } },
        { dataField: 'end', editorType: 'dxDateBox', editorOptions: { type: 'datetime' } },
        { dataField: 'status' },
      ]
    },
    {
      itemType: 'group',
      caption: i18n.t('common.additionalInfo'),
      colCount: 3,
      items: [
        { dataField: 'listing' },
        { dataField: 'journalist' },
        { dataField: 'location' },
        { dataField: 'color' },
        { dataField: 'project_files', colSpan: 2 },
      ]
    }
  ],
})

const defaultEnd = (value) => {
  const defaultEndHourAndMinute = webSiteStore.getDefaultSettings()?.project?.endHour?.split(':')
  if (defaultEndHourAndMinute) {
    return dayjs(value).set('hour', defaultEndHourAndMinute?.[0]).set('minute', defaultEndHourAndMinute?.[1]).toISOString()
  }
  return null
}

const onInitNewRow = (e) => {
  e.data.status = 0
  const defaultStartHourAndMinute = webSiteStore.getDefaultSettings()?.project?.startHour?.split(':')
  if (defaultStartHourAndMinute) {
    e.data.start = dayjs().set('hour', defaultStartHourAndMinute?.[0]).set('minute', defaultStartHourAndMinute?.[1]).toISOString()
  }
  e.data.end = defaultEnd()
}

const alwaysEditableColumns = ['name', 'description', 'status', 'journalist', 'location', 'project_files']

const onEditorPreparing = async(e) => {
  if (e.parentType === 'dataRow') {
    const fieldName = e.dataField
    e.editorOptions.onInitialized = (e) => { editors[fieldName] = e.component }
  }

  if (e.parentType === 'dataRow' && e.dataField === 'start') {
    const defaultOnValueChanged = e.editorOptions.onValueChanged
    e.editorOptions.onValueChanged = (e) => {
      defaultOnValueChanged(e)
      if (editors['end']) {
        editors['end'].option('min', e.value?.toISOString())
        editors['end'].option('value', defaultEnd(e.value))
      }
    }
  }
  if (e.parentType === 'dataRow' && e.dataField === 'end') {
    e.editorOptions.onInitialized = (args) => {
      editors[e.dataField] = args.component
      if (e.row.data?.start) {
        args.component.option('min', e.row.data.start)
      }
    }
  }

  if (e.parentType === 'dataRow' && e.dataField === 'name' && e.row?.data?.id) {
    e.promise = planningStore.fetchProjectByIdAsync(e.row.data?.id).then((project) => {
      for (const editor in editors) {
        if (project.data?.has_listing_items && !alwaysEditableColumns.includes(editor)) {
          editors[editor].option('readOnly', true)
        }
      }
    })
  }
}

const gridOptions = useDefaultGridOptions({
  id: gridId,
  dateSerializationFormat: 'yyyy-MM-ddTHH:mm:ssZ',
  dataSource: gridDataSource,
  columns: columns,
  editing: editing,
  masterDetail: {
    enabled: true,
    template: 'master-detail',
  },
  onInitNewRow: onInitNewRow,
  onEditorPreparing: onEditorPreparing,
  onRowPrepared: onRowPrepared,
  onRowRemoving: onRowRemoving,
  onContentReady: onContentReady,
  clearFilterButton: true,
  archivedToggle: true,
})

const onDownloadProjectFileAsync = async(e) => {
  if (e.value) {
    await planningStore.downloadProjectFileAsync(e.value.id, e.value.name)
  }
}

const onColorChanged = (e, templateOptions) => {
  templateOptions.setValue(e.value)
}

</script>
<template>
  <div>
    <archive-warning-dialog
      v-model="archiveWarning"
      @confirm="async() => await archiveAsync(archiveWarning.item.id, false)"
    />
    <dx-data-grid
      ref="gridRef"
      v-bind="gridOptions"
    >
      <template #name-cell-template="{ data: templateOptions }">
        <span
          :style="{ 'border-left': `4px solid ${templateOptions.data.color}`, 'padding-left': '.25rem' }"
        >{{ templateOptions.data.name }}</span>
      </template>
      <template #master-detail="{ data: templateData }">
        <project-listing-items-grid
          :data="templateData.data"
        />
      </template>
      <template #status-cell-template="{ data: templateOptions }">
        <q-chip
          size="sm"
          :label="planningStore.getLocalizedProjectStatuses()[templateOptions.data.status].name"
          :color="planningStore.getProjectStatusColor(templateOptions.data.status)"
        />
      </template>
      <template #fileUploader="{ data: templateOptions }">
        <file-uploader
          :cell-info="templateOptions"
          @download="onDownloadProjectFileAsync"
        />
      </template>
      <template #colorPicker="{ data: templateOptions }">
        <dx-color-box
          :value="templateOptions.data.color"
          @value-changed="(e) => onColorChanged(e, templateOptions)"
        />
      </template>
      <template #archive-button="{ data: row }">
        <archive-grid-button :row="row" />
      </template>
      <template #archived-toggle>
        <archived-grid-toggle
          :show-archived="showArchived"
          :grid-ref="gridRef"
          @update:show-archived="showArchived = $event"
        />
      </template>
    </dx-data-grid>
  </div>
</template>
