import BaseApi from './base.api'

class PlanningApi extends BaseApi {
  companiesUrl = 'companies/'
  equipmentsUrl = 'equipments/'
  equipmentConditionsUrl = 'equipment-conditions/'
  listingItemsUrl = 'listing-items/'
  listingsUrl = 'listings/'
  projectListingItemServicesUrl = 'project-listing-item-services/'
  projectListingItemsUrl = 'project-listing-items/'
  projectsUrl = 'projects/'
  rentalCompaniesUrl = 'rental-companies/'
  servicesUrl = 'services/'
  staffsUrl = 'staffs/'

  archiveCompanyAsync = (id) => this.putAsync(`${this.companiesUrl}${id}/archive/`)
  archiveEquipmentAsync = (id) => this.putAsync(`${this.servicesUrl}${id}/archive/`)
  archiveStaffAsync = (id) => this.putAsync(`${this.staffsUrl}${id}/archive/`)
  archiveProjectAsync = (id) => this.putAsync(`${this.projectsUrl}${id}/archive/`)
  archiveListingAsync = (id) => this.putAsync(`${this.listingsUrl}${id}/archive/`)
  archiveListingItemAsync = (id) => this.putAsync(`${this.listingItemsUrl}${id}/archive/`)

  deleteEquipmentAsync = (id, config) => this.deleteAsync(`${this.equipmentsUrl}${id}/`, config)
  deleteStaffAsync = (id, config) => this.deleteAsync(`${this.staffsUrl}${id}/`, config)
  deleteProjectAsync = (id, config) => this.deleteAsync(`${this.projectsUrl}${id}/`, config)
  deleteCompanyAsync = (id, config) => this.deleteAsync(`${this.companiesUrl}${id}/`, config)
  deleteListingAsync = (id, config) => this.deleteAsync(`${this.listingsUrl}${id}/`, config)
  deleteListingItemAsync = (id, config) => this.deleteAsync(`${this.listingItemsUrl}${id}/`, config)
  deleteProjectListingItemAsync = (id) => this.deleteAsync(`${this.projectListingItemsUrl}${id}/`)

  downloadProjectFileAsync = (id, fileName) => this.downloadAsync(`${this.projectsUrl}files/${id}`, fileName)

  exportListingsAsync = (params) => this.downloadAsync(`${this.listingsUrl}export-excel/`, 'listings.xlsx', null, { data: {ids: params}, method: 'post' })

  getCompaniesAsync = (params) => this.getAsync(this.companiesUrl, params)
  getEquipmentByIdAsync = (id) => this.getAsync(`${this.equipmentsUrl}${id}`)
  getEquipmentByUuidAsync = (uuid) => this.getAsync(this.equipmentsUrl, { uuid: uuid })
  getEquipmentsAsync = (params) => this.getAsync(this.equipmentsUrl, params)
  getEquipmentConditionsByEquipmentIdAsync = (equipmentId, params) => this.getAsync(this.equipmentConditionsUrl, { ...params, equipment: equipmentId })
  getListingItemsAsync = (params) => this.getAsync(this.listingItemsUrl, params)
  getListingsAsync = (params) => this.getAsync(this.listingsUrl, params)
  getProjectByIdAsync = (id) => this.getAsync(`${this.projectsUrl}${id}`)
  getProjectListingItemsAsync = (projectId, params) => this.getAsync(this.projectListingItemsUrl, { ...params, project: projectId })
  getProjectListingItemsByProjectIdAsync = (projectId, params) => this.getAsync(this.projectListingItemsUrl, { ...params, project: projectId })
  getProjectListingItemServicesAsync = (params) => this.getAsync(this.projectListingItemServicesUrl, params)
  getProjectListingItemServicesByProjectIdAsync = (projectId, params) => this.getProjectListingItemServicesAsync({ ...params, project: projectId })

  getProjectsAsync = (params) => this.getAsync(this.projectsUrl, params)
  getProjectServicesAsync = (params) => this.getAsync(this.projectServicesUrl, params)
  getProjectServicesByProjectIdAsync = (projectId, params) => this.getAsync(this.projectServicesUrl, { ...params, project: projectId })
  getRentalCompaniesAsync = (params) => this.getAsync(this.rentalCompaniesUrl, params)
  getServicesAsync = (params) => this.getAsync(this.servicesUrl, params)
  getServiceEventsAsync = (id, params) => this.getAsync(`${this.servicesUrl}${id}/events/`, params)
  getStaffsAsync = (params) => this.getAsync(this.staffsUrl, params)

  downloadEquipmentQrCodesAsync = (fileName, ids) => this.downloadAsync(`${this.equipmentsUrl}qr-codes/`, fileName, null, { data: { ids }, method: 'post' })

  upsertProjectAsync = (data) => this.upsertAsync(this.projectsUrl, data)
  upsertEquipmentAsync = (data, params) => this.upsertAsync(this.equipmentsUrl, data, { params: params })
  upsertEquipmentConditionAsync = (data, params) => this.upsertAsync(this.equipmentConditionsUrl, data, { params: params })
  upsertProjectListingItemAsync = (data) => this.upsertAsync(this.projectListingItemsUrl, data)
  upsertProjectListingItemServiceAsync = (data, params) => this.upsertAsync(this.projectListingItemServicesUrl, data, { params: params })
}

export const planningApi = new PlanningApi('planning/')
