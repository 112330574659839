<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const i18n = useI18n()

const props = defineProps({
  showArchived: {
    type: Boolean,
    default: false,
  },
  gridRef: Object,
})

const emit = defineEmits(['update:showArchived'])

const _showArchived = ref(props.showArchived)

const onToggleArchived = async() => {
  _showArchived.value = !_showArchived.value
  emit('update:showArchived', _showArchived.value)
  props.gridRef.instance.refresh()
}

</script>
<template>
  <q-toggle
    :model-value="_showArchived"
    :label="i18n.t('common.showArchived')"
    color="primary"
    style="align-self: flex-end;"
    @update:model-value="onToggleArchived"
  />
</template>
