import dayjs from 'dayjs'
export const isNotEmpty = (value) => value !== undefined && value !== null && value !== ''

export const stringToHsl = (str) => {
  if (!str) return [0, 0, 0]

  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = ((hash << 5) - hash) + str.charCodeAt(i)
    hash |= 0
  }

  const hue = Math.abs(hash % 360)

  const saturation = 60 + Math.abs(hash % 20)

  const lightness = 60 + Math.abs(hash % 20)

  return [hue, saturation, lightness]
}

export const stringToColor = (str) => {
  const stringToHslValue = stringToHsl(str)
  return `hsl(${stringToHslValue[0]}, ${stringToHslValue[1]}%, ${stringToHslValue[2]}%)`
}

export const stringToRgb = (str) => {
  const stringToHslValue = stringToHsl(str)
  return hslToRgb(stringToHslValue[0], stringToHslValue[1], stringToHslValue[2])
}

const hslToRgb = (h, s, l) => {
  h = h / 360
  s = s / 100
  l = l / 100

  let r, g, b

  if (s === 0) {
    r = g = b = l
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q

    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return [
    Math.round(r * 255),
    Math.round(g * 255),
    Math.round(b * 255)
  ]
}

export const getInitials = (name) => {
  if (!name) { return '' }
  return name.split(' ').map(n => n[0]).join('').toUpperCase()
}

export const formatDate = (date = new Date()) => {
  if (date) {
    return dayjs(date).format('YYYY/MM/DD')
  }
  return ''
}

export const formatTime = (date = new Date()) => {
  if (date) {
    return dayjs(date).format('HH:mm')
  }
  return ''
}

export const formatString = (value, format) => {
  if (!value) return ''
  for (const char of value) {
    format = format.replace('#', char)
  }
  return format
}

export const stringFormats = {
  PHONE: '+## (###) ###-####',
}

// eslint-disable-next-line no-extend-native
Array.prototype.sortBy = function(fn) {
  return this.sort((a, b) => {
    return fn(a)?.localeCompare(fn(b))
  })
}
