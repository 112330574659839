import { ref } from 'vue'
import moment from 'moment'
import { jsPDF } from 'jspdf'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver'
import i18n from '@/plugins/i18n'

import TextBox from 'devextreme/ui/text_box'
import SelectBox from 'devextreme/ui/select_box'
import DropDownBox from 'devextreme/ui/drop_down_box'
import TagBox from 'devextreme/ui/tag_box'
import DateBox from 'devextreme/ui/date_box'
import NumberBox from 'devextreme/ui/number_box'
import CheckBox from 'devextreme/ui/check_box'
import { formatNumber } from 'devextreme/localization'
import { exportDataGrid as pdfExporter } from 'devextreme/pdf_exporter'
import { exportDataGrid as excelExporter } from 'devextreme/excel_exporter'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'

import { useWebSiteStore } from '@/stores/web-site.store'

import { useException } from '@/composables/exceptions'

const altPressed = ref(false)

const enterPressedTimestamp = ref(0)
const escapePressedTimestamp = ref(0)

export const focusedRow = ref(null)
export const masterGridFocusedRow = ref(null)
export const focusedGrid = ref(null)

export const editingModeEnum = {
  NONE: 0,
  INSERT: 1,
  UPDATE: 2,
  DELETE: 3,
}

export const editingMode = ref(0)

export const useGridRef = () => {
  const gridRef = ref(null)
  const gridIsEditing = () => editingMode.value === editingModeEnum.INSERT || editingMode.value === editingModeEnum.UPDATE
  return { gridRef, focusedRow, editingMode, editingModeEnum, gridIsEditing }
}

export const useCurrencyColumnFormatter = (rowData, field, currencyId) => {
  const webSiteStore = useWebSiteStore()
  const defaultCurrencyCode = webSiteStore.getDefaultCurrency()?.iso_code
  const currentCurrency = webSiteStore.currencies.find(i => i.id === currencyId)
  return rowData[field] && formatNumber(+rowData[field], { style: 'currency', currency: currentCurrency?.iso_code || defaultCurrencyCode, useGrouping: true })
}

export const usePercentageOptions = (options = null) => {
  const opts = {...{ precision: 2, isEditor: false }, ...options}
  const format = { type: 'percent', precision: opts.precision }
  return opts.isEditor ? { format: format, step: 0.01 } : format
}

export const currencyPrecision = (currency) => {
  const numberFormatUSD = new Intl.NumberFormat('en-US', { style: 'currency', currency })
  return numberFormatUSD.formatToParts(1)
    .find(part => part.type === 'fraction')
    ?.value.length ?? 0
}

export const useCurrencyOptions = (options = null) => {
  const webSiteStore = useWebSiteStore()
  const currencyCode = options?.currency || webSiteStore.getDefaultCurrency()?.iso_code
  const format = { type: 'currency', currency: currencyCode, precision: currencyPrecision(currencyCode), useGrouping: true }
  return { format: format }
  // return { format: { style: 'currency', currency: currency, useGrouping: true }, ...options }
}

export const useDateConverter = (model, excludedFields = []) => {
  for (const prop in model) {
    if (!excludedFields.includes(prop) && model[prop] instanceof Date) {
      model[prop] = moment(model[prop]).format('YYYY-MM-DD')
    }
  }
  return model
}

export const useLookup = (options = null) => {
  const defaultOptions = {
    valueExpr: 'id',
    sort: 'code',
    displayExpr: (obj) => !obj ? '' : `${obj.code} - ${obj.name}`
  }

  const opts = {...defaultOptions, ...options }
  return {
    valueExpr: opts.valueExpr,
    dataSource: opts.dataSource || { store: opts.store, sort: opts.sort },
    displayExpr: opts.displayExpr,
    ...opts
  }
}

export const useDefaultPriceRounding = (e, toPrecisionFields, precision = 4) => {
  for (const field of toPrecisionFields) {
    if (e.newData[field]) {
      e.newData[field] = +e.newData[field].toPrecision(4)
    }
  }
}

export const useDefaultOnRowInserting = (e) => useDateConverter(e.data)
export const useDefaultOnRowUpdating = (e) => useDateConverter(e.newData)

export const useDefaultOnInitNewRow = (e) => {
  e.component.keyboardListenerEnabled = false
  editingMode.value = editingModeEnum.INSERT
  const editingPopupTitleFn = e.component.option('editing.popup.titleFn')
  if (editingPopupTitleFn) {
    e.component.option('editing.popup.title', editingPopupTitleFn(e.data))
  }
}

export const useDefaultOnEditingStart = (e) => {
  e.component.keyboardListenerEnabled = false
  editingMode.value = editingModeEnum.UPDATE

  const editingPopupTitleFn = e.component.option('editing.popup.titleFn')
  if (editingPopupTitleFn) {
    e.component.option('editing.popup.title', editingPopupTitleFn(e.data))
  }
}

export const useDefaultOnEditCanceled = (e) => {
  e.component.keyboardListenerEnabled = true
  editingMode.value = editingModeEnum.NONE
  useFocusOnDatagrid(e)
}

export const useDefaultPager = {
  showPageSizeSelector: true,
  allowedPageSizes: [10, 20, 50],
  showInfo: true,
  showNavigationButtons: true,
  showPageSizes: true,
  visible: true,
}

export const useDefaultPaging = {
  pageSize: 20,
  enabled: true,
}

export const useDefaultScrolling = {
  mode: 'virtual',
  rowRenderingMode: 'virtual',
}

export const useDefaultScrollingPaging = {
  pageSize: 21,
}

export const useDefaultFilterRow = {
  visible: true,
  applyFilter: 'auto'
}

export const useDefaultSorting = {
  mode: 'multiple'
}

export const useDefaultHeaderFilter = {
  visible: true,
  allowSearch: true,
}

export const useDefaultColumnChooser = {
  enabled: true,
  mode: 'select',
  position: { my: 'right top', at: 'right bottom', of: '.dx-datagrid-column-chooser-button' },
}

export const useDefaultExport = (options) => {
  options = {
    fileName: 'export',
    isLandscape: true,
    formats: ['XLSX'],
    ...options
  }

  const exportOptions = {
    enabled: true,
    allowExportSelectedData: true,
    formats: options.formats,
  }

  const exportToPdf = async(e) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF(options.isLandscape ? 'l' : 'p', 'mm', [210, 297]) // portrait A4

    await pdfExporter({
      jsPDFDocument: doc,
      component: e.component,
      indent: 5,
      customizeCell: options.customizeCellPdf,
    })

    doc.save(`${options.fileName}.pdf`)
  }

  const exportToExcel = async(e) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet(options.fileName)

    await excelExporter({
      component: e.component,
      worksheet,
      customizeCell: options.customizeCellExcel,
    })
    const buffer = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${options.fileName}.${e.format.toLowerCase()}`)
  }

  const onExporting = async(e) => {
    e.cancel = true
    if (e.format === 'PDF') {
      await exportToPdf(e)
    } else {
      await exportToExcel(e)
    }
  }

  return { exportOptions, onExporting, exportToExcel, exportToPdf }
}

export const useDefaultErrorHandler = (e) => {
  const { t } = i18n.global

  const setValidationOnEditor = (editor, detail, label) => {
    editor.option({
      validationStatus: 'invalid',
      validationErrors: detail.errors.map(i => {
        return { message: t(i.code, {field: label, ...i.params}) || i.code || i }
      })
    })
  }

  let isManaged = false
  if (e.error?.httpStatus >= 400 && e.error?.httpStatus < 500) {
    if (e?.error?.errorDetails?.details && e?.error?.errorDetails.details.length > 0) {
      e.error.errorDetails.details.forEach(detail => {
        const searchRowIndex = editingMode.value === editingModeEnum.INSERT ? 0 : focusedRow.value.rowIndex

        // The get cell element uses the rowIndex and the column index to get the cell element from the GRID or from the "edit" POPUP
        // If you do not specify the current editing row index, the cell element will be retrieved from the GRID
        const cellElement = e.component.getCellElement(searchRowIndex, detail.target)
        if (cellElement) {
          let editorElements = cellElement.getElementsByClassName('dx-texteditor')
          let editorInstance = TextBox.getInstance(editorElements[0])
          if (!editorInstance) {
            editorInstance = SelectBox.getInstance(editorElements[0])
          }
          if (!editorInstance) {
            editorInstance = DropDownBox.getInstance(editorElements[0])
          }
          if (!editorInstance) {
            editorInstance = TagBox.getInstance(editorElements[0])
          }
          if (!editorInstance) {
            editorInstance = DateBox.getInstance(editorElements[0])
          }
          if (!editorInstance) {
            editorInstance = NumberBox.getInstance(editorElements[0])
          }
          if (!editorInstance) {
            editorElements = cellElement.getElementsByClassName('dx-checkbox')

            for (const item of editorElements) {
              const inputs = item.getElementsByTagName('input')
              if (inputs.length && inputs[0].value === 'null') {
                editorInstance = CheckBox.getInstance(item)
                break
              }
            }
          }
          if (editorInstance) {
            isManaged = true
            const label = editorInstance.option('label')
            setValidationOnEditor(editorInstance, detail, label)
          }
        }
      })
    }
  }
  if (!isManaged) {
    console.error(e.error?.errorDetails || e.error)
    const { processError } = useException()
    processError(e.error)
  }
}

export const useDefaultEditing = (title, form, options = null) => {
  const { t } = i18n.global

  const defaultOptions = {
    width: '70%', height: 'auto',
    allowAdding: true,
    allowUpdating: true,
    allowDeleting: true,
  }
  const opts = {...defaultOptions, ...options}

  return {
    allowAdding: opts.allowAdding,
    allowUpdating: opts.allowUpdating,
    allowDeleting: opts.allowDeleting,
    useIcons: true,
    mode: 'popup',
    popup: {
      title: title || t('common.edit'),
      titleFn: typeof title === 'function' ? title : null,
      showTitle: true,
      width: opts.width || 'auto',
      height: opts.height || 'auto',
    },
    form: form || {},
  }
}

export const useDefaultStateStoring = (gridId) => {
  const webSiteStore = useWebSiteStore()
  const gridLoadState = (gridId) => {
    const gridState = webSiteStore.gridStates[gridId]
    if (gridState) {
      // clearing filters
      gridState.columns?.forEach((col) => {
        if (col.filterValue) {
          col.filterValue = null
        }
      })
      // clearing selected row
      gridState.selectedRowKeys = null
      return gridState
    }
    return gridState
  }

  const gridSaveState = (gridId, state) => {
    webSiteStore.setGridState(gridId, state)
  }

  return {
    enabled: true,
    type: 'custom',
    customLoad: () => gridLoadState(gridId),
    customSave: (state) => gridSaveState(gridId, state)
  }
}

export const masterGrid = ref(null)
export const detailGrid = ref(null)

export const gridAllowEditingAction = (grid, action) => {
  /// check if is function
  if (grid.option('editing')[action] instanceof Function) {
    return grid.option('editing')[action]({row: focusedRow.value})
  }
  return grid.option('editing')[action]
}

export const useDefaultKeyDownListener = () => {
  return (event) => {
    if (event.code === 'AltLeft' || event.code === 'AltRight') {
      altPressed.value = true
    } else if (altPressed.value && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
      event.preventDefault()
    } else if (event.code === 'F3' && !editingMode.value && focusedGrid.value?.keyboardListenerEnabled && gridAllowEditingAction(focusedGrid.value, 'allowAdding')) {
      focusedGrid.value.addRow()
      event.preventDefault()
    } else if (event.code === 'F4' && !editingMode.value && focusedGrid.value?.keyboardListenerEnabled && gridAllowEditingAction(focusedGrid.value, 'allowUpdating')) {
      focusedGrid.value.editRow(focusedRow.value.rowIndex)
      event.preventDefault()
    } else if (event.code === 'F6' && !editingMode.value && focusedGrid.value?.keyboardListenerEnabled && gridAllowEditingAction(focusedGrid.value, 'allowDeleting')) {
      focusedGrid.value.deleteRow(focusedRow.value.rowIndex)
      editingMode.value = editingModeEnum.DELETE
      event.preventDefault()
    }
  }
}

export const useDefaultKeyUpListener = () => {
  return (event) => {
    if (event.code === 'AltLeft' || event.code === 'AltRight') {
      altPressed.value = false
    } else if (editingMode.value && editingMode.value !== editingModeEnum.DELETE && event.key === 'Enter') {
      if ((Date.now() - enterPressedTimestamp.value) < 500) {
        document.activeElement.blur()
        focusedGrid.value.getController('validating').validate(true).done(isValid => isValid && focusedGrid.value.saveEditData())
      } else {
        enterPressedTimestamp.value = Date.now()
      }
      event.preventDefault()
      event.stopPropagation()
    } else if (editingMode.value === editingModeEnum.DELETE && event.key === 'Escape') {
      focusedGrid.value.cancelEditData()
      event.preventDefault()
      event.stopPropagation()
    } else if (editingMode.value && event.key === 'Escape') {
      if ((Date.now() - escapePressedTimestamp.value) < 500) {
        focusedGrid.value.cancelEditData()
        focusedGrid.value.refresh()
      } else {
        escapePressedTimestamp.value = Date.now()
      }
      event.preventDefault()
      event.stopPropagation()
    }
    if (altPressed.value) {
      altPressed.value = false
      if (event.key === 'ArrowDown' && !editingMode.value && masterGridFocusedRow.value) {
        masterGrid.value.expandRow(masterGridFocusedRow.value.key)
        masterGrid.value.keyboardListenerEnabled = false // to avoid the default behavior of the grid while opening the detail
        event.preventDefault()
      } else if (event.key === 'ArrowUp' && !editingMode.value && masterGridFocusedRow.value) {
        if (masterGrid.value.isRowExpanded(masterGridFocusedRow.value.key)) {
          masterGrid.value.collapseRow(masterGridFocusedRow.value.key)
          masterGrid.value.refresh()
        }
        event.preventDefault()
      }
    }
  }
}

export const useDefaultOnFocusedRowChanging = (e) => {
  if (altPressed.value) {
    e.cancel = true
  }
  if (!e.component.keyboardListenerEnabled) {
    e.cancel = true
  }
}

export const useDefaultOnFocusedRowChanged = (e) => {
  focusedRow.value = e.row
  if (e.component.option('masterDetail').enabled) {
    masterGrid.value = e.component
    masterGridFocusedRow.value = e.row
  }
  e.component.keyboardListenerEnabled = true
}

export const useDefaultKeyHandler = () => {
  const keyDownListener = useDefaultKeyDownListener()
  const keyUpListener = useDefaultKeyUpListener()
  document.addEventListener('keydown', keyDownListener)
  document.addEventListener('keyup', keyUpListener)
}

export const useDefaultOnContentReady = (e) => {
  const grid = e.component
  if (grid) {
    if (!grid.firstLoad) {
      grid.firstLoad = true
      setTimeout(() => {
        useFocusOnDatagrid(e)
      }, 50)
    }

    grid.columnOption('command:edit', 'visibleIndex', -1)
    grid.columnOption('command:edit', 'fixed', 'true')
    grid.columnOption('command:edit', 'fixed-position', 'left')
  }
}

export const useFocusOnDatagrid = (e) => {
  const grid = e.component
  focusedGrid.value = grid
  if (grid.option('masterDetail')?.enabled) {
    masterGrid.value = grid
  }
  return setFocusOnDatagrid(grid)
}

const setFocusOnDatagrid = (grid) => {
  if (grid) {
    grid.focus()
    grid.keyboardListenerEnabled = true
  }
}

export const useDefaultAddressEditing = (addressType, colSpan, colCount) => {
  const { t } = i18n.global
  return {
    itemType: 'group',
    caption: t('common.address'),
    colSpan: colSpan,
    colCount: colCount,
    items: [
        { dataField: `${addressType}.country`, label: { text: t('common.country') } },
        { dataField: `${addressType}.state`, label: { text: t('common.state') }, editorOptions: { showClearButton: true } },
        { dataField: `${addressType}.city`, label: { text: t('common.city') }},
        { dataField: `${addressType}.zip_code`, label: { text: t('common.zipCode') } },
        { dataField: `${addressType}.street`, label: { text: t('common.street') }, colSpan: 2 },
    ]
  }
}

export const onDefaultSaved = (e) => {
  editingMode.value = editingModeEnum.NONE
  useFocusOnDatagrid(e)
}

export const onDefaultRowRemoved = (e) => {
  editingMode.value = editingModeEnum.NONE
  useFocusOnDatagrid(e)
}

export const useDefaultOnInitialized = (e, options = null) => {
  const grid = e.component
  grid.on('initNewRow', (e) => useDefaultOnInitNewRow(e))
  grid.on('editingStart', (e) => useDefaultOnEditingStart(e))
  grid.on('rowInserting', (e) => useDefaultOnRowInserting(e))
  grid.on('rowUpdating', (e) => useDefaultOnRowUpdating(e))
  grid.on('rowRemoved', (e) => onDefaultRowRemoved(e))
  grid.on('editCanceled', (e) => useDefaultOnEditCanceled(e))
  grid.on('contentReady', (e) => useDefaultOnContentReady(e))
  grid.on('focusedRowChanged', (e) => useDefaultOnFocusedRowChanged(e))
  grid.on('focusedRowChanging', (e) => useDefaultOnFocusedRowChanging(e))
  grid.on('rowCollapsed', (e) => useFocusOnDatagrid(e))
  grid.on('saved', (e) => onDefaultSaved(e))
  grid.on('keyDown', (e) => {
    if (e.event.key === 'ArrowLeft' || e.event.key === 'ArrowRight' || e.event.key === 'Tab') {
      e.handled = true
      e.event.preventDefault()
    }
  })

  const toolbarItems = [
    { name: 'addRowButton' },
    { name: 'exportButton' },
    { name: 'columnChooserButton' },
  ]

  if (options?.exportExcelButton) {
    toolbarItems.push({
      widget: 'dxButton',
      location: 'after',
      options: {
        icon: 'mdi mdi-file-excel',
        hint: i18n.global.t('common.exportExcel'),
        disabled: false,
        onClick: (e) => {
          if (options.onExportExcel) {
            options.onExportExcel({ component: grid })
          }
          grid.instance().clearFilter()
        },
      }
    })
  }

  if (options?.clearFilterButton) {
    toolbarItems.push({
      widget: 'dxButton',
      location: 'after',
      options: {
        icon: 'mdi mdi-filter-off',
        hint: i18n.global.t('common.clearFilter'),
        disabled: false,
        onClick: (e) => {
          if (options.onClearFilter) {
            options.onClearFilter({ component: grid })
          }
          grid.instance().clearFilter()
        },
      }
    })
  }

  if (options?.archivedToggle) {
    toolbarItems.unshift({
      location: 'after',
      template: 'archived-toggle',
    })
  }

  grid.option('toolbar', { items: toolbarItems })
}

export const useDefaultGridOptions = (options = null) => {
  return {...{
    // columns
    // https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/ColumnCustomization/Vue/Light/
    allowColumnReordering: true,
    allowColumnResizing: true,
    showColumnLines: true,
    columnChooser: useDefaultColumnChooser,
    showBorders: true,
    remoteOperations: true,
    columnAutoWidth: true,
    pager: useDefaultPager,
    paging: useDefaultPaging,
    stateStoring: (options?.saveStateId || options?.id) && useDefaultStateStoring(options?.saveStateId || options?.id),
    repaintChangesOnly: true,
    columnFixing: {
      enabled: true,
    },

    // sorting & filtering
    // https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/MultipleSorting/Vue/Light/
    sorting: useDefaultSorting,
    filterRow: useDefaultFilterRow,

    // focused row
    // https://js.devexpress.com/Demos/WidgetsGallery/Demo/DataGrid/FocusedRow/Vue/Light/
    focusedRowEnabled: true,
    autoNavigateToFocusedRow: true,

    // show validation errors
    // https://supportcenter.devexpress.com/ticket/details/t933572/datagrid-how-to-display-validation-errors-that-came-from-the-server
    errorRowEnabled: false,
    onDataErrorOccurred: useDefaultErrorHandler,
    onInitialized: (e) => useDefaultOnInitialized(e, options),
  }, ...options}
}

export const useODataStore = (url, options = null) => {
  const store = new ODataStore({
    url: url,
    beforeSend: (e) => {
      const webSiteStore = useWebSiteStore()

      if (options?.customParamsFn) {
        const params = options.customParamsFn(e.params)
        if (params) {
          e.params = {...e.params, ...params}
        }
      }
      e.headers = {
        ...e.headers,
        'Authorization': 'Bearer ' + webSiteStore.accessToken,
        'Accept-Language': i18n.global.locale.value,
        'x-odata': 'true',
      }

      // remove from params the id if the value is not a number
      if (e.params?.$filter && e.params.$filter.includes('id')) {
        const match = e.params.$filter.match(/id eq (\w+)/)
        if (!match || isNaN(match[1])) {
          e.params.$filter = e.params.$filter.replace(/id eq \'(.*)\'/, '')
        }
        if (e.params.$filter === '') {
          delete e.params.$filter
        }
      }

      if (e.method === 'PUT' || e.method === 'PATCH' || e.method === 'DELETE') {
        const id = e.url.match(/\((\d+)\)/)[1]
        e.url = url + id + '/'
      } else {
        e.url = url
      }
    },
    deserializeDates: false,
    withCredentials: true,
    key: options?.key || 'id',
    keyType: 'Int32',
    version: 4
  })

  store.byKey = (key) => {
    // if (dataSource.cache.value && dataSource.cache.value.length > 0) {
    //   const element = dataSource.cache.value.find(x => x.id === key)
    //   if (element) {
    //     return new Deferred().resolve(element)
    //   }
    // }
    if (!key || key === '') {
      return store.load()
    }
    if (typeof key === 'string') {
      const searchExpr = options?.searchExpr || ['code']
      const filter = searchExpr.map((expr) => {
        return [expr, 'contains', key]
      })
      return store.load({ filter: filter })
    }
    return store.load({ filter: ['id', '=', key] })
  }

  return store
}

export const useODataSource = (url, options = null) => {
  const store = useODataStore(url, options)

  const dataSourceOptions = {
    store: store,
    searchExpr: options?.searchExpr,
    sort: options?.sort,
    pageSize: options?.pageSize || 20,
    postProcess: options?.postProcess,
    filter: options?.filter,
  }

  // const dataSource = new DataSource(dataSourceOptions)
  // dataSource.cache = ref([])

  // store.on('loaded', (e) => {
  //   dataSource.cache.value = e
  // })

  return new DataSource(dataSourceOptions)
}

export const useCustomDataSource = (url, key) => {
  return createStore({
    key: key,
    loadUrl: url,
    onBeforeSend(method, ajaxOptions) {
      const webSiteStore = useWebSiteStore()
      e.headers = {
        ...e.headers,
        'Authorization': 'Bearer ' + webSiteStore.accessToken,
      }
    },
  })
}

export const useMask = () => {
  return {
    mobileMask: '000 00 00 0099',
    phoneMask: '0099 00000099',
    dateDisplayFormat: 'dd/MM/yyyy',
    dateSerializationFormat: 'yyyy-MM-dd',
    percentageMask: '#0.##%',
  }
}

export const useUpdateMasterDetail = (options = null) => {
  const repaintRow = async(gridRef, apiMethod, id, rowKey) => {
    if (id) {
      await apiMethod(id)
      .then(res => {
        const index = gridRef.value.instance.getRowIndexByKey(rowKey)
        const row = gridRef.value.instance.getVisibleRows()[index].data
        Object.assign(row, res.data)
        gridRef.value.instance.repaintRows([index])
      })
    }
  }

  return { repaintRow }
}

export const useCustomValidation = (options = null) => {
  const webSiteStore = useWebSiteStore()

  const vatNumberValidation = (event, vatCountryId, isoCode, maxLength) => {
    return webSiteStore.countries.find(x => x.id === vatCountryId)?.iso3_code === isoCode ? event.value.length <= maxLength : true
  }

  return { vatNumberValidation }
}

export const useDefaultDialogTitle = () => {
  const { t } = i18n.global

  const defaultDialogTitle = (item, dialogName) => {
    const title = `${defaultActionTitle(item)} ${dialogName}`
    if (!item?.id) {
      return title
    }
    const subTitle = defaultItemTitle(item)
    return subTitle ? `${title} - ${subTitle}` : title
  }

  const defaultActionTitle = (item) => {
    return `[${!item?.id ? t('common.add') : t('common.edit')}]`
  }

  const defaultItemTitle = (item) => {
    let title = ''
    if (!item) {
      return title
    }
    if (item.name) {
      title += ` ${item.name}`
    }
    return title
  }

  return { defaultDialogTitle, defaultActionTitle, defaultItemTitle }
}
