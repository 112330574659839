import { scheduleApi } from '@/apis/schedule.api'
import { defineStore } from 'pinia'

export const initScheduleStoreAsync = async() => {
}

export const useScheduleStore = defineStore('schedule', () => {
  const eventTypeEnum = Object.freeze({
    PROJECT: 0,
    SERVICE_AVAILABILITY: 1,
  })

  const fetchEventsAsync = (params) => scheduleApi.getEventsAsync(params)
  const fetchEventsByServiceAsync = (serviceId, params) => scheduleApi.getEventsByServiceAsync(serviceId, params)
  const fetchEventWarningsAsync = (params) => scheduleApi.getEventWarningsAsync(params)
  const fetchServiceAvailabilitiesByServiceAsync = (serviceId, params) => scheduleApi.getServiceAvailabilitiesByServiceAsync(serviceId, params)
  const fetchProjectEventsByServiceAsync = (serviceId, params) => scheduleApi.getProjectEventsByServiceAsync(serviceId, params)
  const upsertEventAsync = (event) => scheduleApi.upsertEventAsync(event)
  const upsertServiceAvailabilityAsync = async(serviceAvailability) => scheduleApi.upsertServiceAvailabilityAsync(serviceAvailability)

  const deleteEventAsync = async(eventId) => scheduleApi.deleteEventAsync(eventId)

  const $reset = () => {
  }

  return {
    eventTypeEnum,
    fetchEventsAsync,
    fetchEventsByServiceAsync,
    fetchEventWarningsAsync,
    fetchServiceAvailabilitiesByServiceAsync,
    fetchProjectEventsByServiceAsync,
    upsertEventAsync,
    upsertServiceAvailabilityAsync,
    deleteEventAsync,
    $reset,
  }
})
