import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useNotify } from '@/composables/notify'
import { usePlanningStore } from '@/stores/planning.store'

export const useArchive = (options) => {
  const i18n = useI18n()
  const planningStore = usePlanningStore()
  const { notifySuccess } = useNotify()

  const showArchived = ref(false)

  const archiveWarning = ref({
    dialogShow: false,
    warnings: [],
    message: '',
    item: null,
  })

  const archiveDisplayExpr = (data, field) => {
    if (!data) {
      return ''
    }

    const field_value = typeof field === 'function' ? field(data) : data[field]
    return data.archived ? `${field_value} (${i18n.t('common.archived')})` : field_value
  }

  const archiveCellTemplate = (element, rowData, entity, field) => {
    if (!entity) {
      return
    }

    const field_value = typeof field === 'function' ? field(entity) : entity[field]
    element.textContent = field_value

    if (entity.archived) {
      element.style.fontStyle = 'italic'
      element.textContent = `${field_value} (${i18n.t('common.archived')})`
      element.style.color = 'grey'
      element.style.textDecoration = 'line-through'
    }
  }

  const archiveAsync = async(id, archived) => {
    await options.onArchive(id)
    if (archived) {
      notifySuccess(i18n.t('info.restoredSuccessfully'))
    } else {
      notifySuccess(i18n.t('info.archivedSuccessfully'))
    }
    options.gridRef.value.instance.refresh()
  }

  const onRowRemoving = async(e) => {
    e.cancel = true
    try {
      await options.onDelete(e.data.id, { skipErrorMessage: true })
      await options.gridRef.value.instance.refresh()
    } catch (error) {
      if (error.response?.status === 409) {
        await planningStore.fetchProjectsAsync({all: true}, true)
        archiveWarning.value.warnings = error.response.data?.details[0]?.errors[0]?.params?.map(i => {
          const projectId = +(i.project_id)
          const project = projectId ? planningStore.getProjectById(projectId) : planningStore.getVacationProject(t)
          return {
            project: project
          }
        })
        archiveWarning.value.message = i18n.t(error.response.data?.details[0]?.errors[0]?.code)
        archiveWarning.value.dialogShow = true
        archiveWarning.value.item = e.data
      }
    }
  }

  const onRowPrepared = (e) => {
    if (e.rowType === 'data' && e.data?.archived_at) {
      e.rowElement.classList.add('dx-disabled-row')
    }
  }

  const archiveButton = {
    text: i18n.t('common.archive'),
    template: 'archive-button',
    onClick: (e) => archiveAsync(e.row?.data?.id, e.row?.data?.archived),
  }

  return {
    archiveButton,
    archiveWarning,
    showArchived,
    archiveAsync,
    archiveCellTemplate,
    archiveDisplayExpr,
    onRowPrepared,
    onRowRemoving,
  }
}
