import script from "./EventScheduler.vue?vue&type=script&setup=true&lang=js"
export * from "./EventScheduler.vue?vue&type=script&setup=true&lang=js"

import "./EventScheduler.vue?vue&type=style&index=0&id=4ef85046&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import qInstall from "../../../node_modules/.pnpm/vue-cli-plugin-quasar@5.0.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QDialog,QSpace,QItemLabel,QIcon,QChip});
