import script from "./EventAgenda.vue?vue&type=script&setup=true&lang=js"
export * from "./EventAgenda.vue?vue&type=script&setup=true&lang=js"

import "./EventAgenda.vue?vue&type=style&index=0&id=68a7c302&lang=scss"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/.pnpm/vue-cli-plugin-quasar@5.0.2/node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QSpace,QSeparator,QItem,QItemSection,QIcon,QItemLabel});qInstall(script, 'directives', {Ripple});
